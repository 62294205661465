import { faCirclePlus,faRefresh } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import Btnwithicon from '../../Components/Btnwithicon'
import Inputcom from '../../Components/Inputcom'
import InputSelectcom from '../../Components/InputSelectcom'
import Radiobtncom from '../../Components/Radiobtncom'
import Selectcom from '../../Components/Selectcom'
import Textareacom from '../../Components/Textareacom'
import {useDispatch, useSelector} from 'react-redux'
import { fetchaddtxn, fetchtxn, fetchtxnuser } from '../../redux/actions/txnaction'
import {fethselectedtxn, updateselectedtxn} from '../../redux/actions/selectedaction'
import NewtestPop from '../../Components/NewtestPop'
import { useLocation, useParams } from 'react-router-dom'
import InputSelectMyCom from '../../Components/InputSelectMyCom'
import Newinpusel from '../../Components/Newinpusel'
import SimpleinpuSelect from '../../Components/SimpleinpuSelect'
import gpeapi from '../../api/gpeapi'
import Popupnoti from '../../Components/Popupnoti'
import { fetchwitxn } from '../../redux/actions/witxnaction'
import Loadingmy from '../../Components/Shortcom/Loadingmy'




const Addtxn = () => {

  const token = localStorage.getItem('token')
    const {id} = useParams()
    const location = useLocation()

// console.log(id)


    const state5 = useSelector((state)=>state.selectedReducer)

    


    const dispatch = useDispatch()

    const [isloading, setisloading] = useState(false)

    

    const [mydta,setmydta]= useState({
        txnname:'',
        userid:location?.state?.uid==undefined?'':location?.state?.uid,
        amount:'',
        type:'',
        status:''
    })

    const [notidata, setnotidata] = useState({
        msg:'',
        success:'',
        show:''
    })


    

    let typedata = ['Paid','Received','Added','Cashback']
    let statusdata = ['Successful','Pending','Rejected']

    // const state = useSelector((state)=>state.userReducer).sort((a,b)=>a.id-b.id)
    const state1 = useSelector((state)=>state.offerReducer)
    const state2 = useSelector((state)=>state.storeReducer)

    const idArr = []
    const offerArr = []

    // const myid = state.map((val)=>idArr.push({id:val.id}))
    const myoffer = state1.map((val)=>offerArr.push({name:val.name}))
    const myoffer2 = state2.map((val)=>offerArr.push({name:val.name}))

    const handleChnage = (e)=>{
        const value = e.target.value;
        const name = e.target.name;
        setmydta({...mydta,[name]:value})
    }
    const handlemyNew = (val)=>{
        setmydta({...mydta,txnname:val.name})
    }

   

    const handleChnageny = (val)=>{
        setmydta({...mydta,userid:val.id})
    }


    // console.log('ss',state5)
   

    

    const handlclk =async ()=>{
      await setisloading(true)
        id? await gpeapi.put(`/updatetxnhistory/${id}`,mydta,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
        :await dispatch(fetchaddtxn(mydta))
        // console.log(state5)
        await setisloading(false)

        

        // console.log(mydta)
    }

    
    const callingAPI = ()=>{
       
        dispatch(fethselectedtxn(id))
        
        
    }

   

    // console.log(isloading)
    
    

    


    
    const updateForm = async ()=>{
        
        const userData = await state5.filter((val)=>val.txnid==id)

        // console.log(userData)
        
        userData.map((val)=>setmydta({type:val.type,status:val.status,userid:val.userid,amount:val.amount,txnname:val.txnname}))
       
        // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
        
    }

    

    // console.log(state5[0]?.id)
    const match = state5[0]?.txnid==id
  

    useEffect(()=>{
        id && callingAPI()
        updateForm()
    },[match,id])




    
   

     

      

      const handleSearch = (value, cb) => {
        // just to explian API call
        let tempOptions = [...idArr];
        tempOptions = tempOptions.filter((item) =>
          item["id"].toString()?.includes(value)
        );
        // setOptions
        cb(tempOptions);
      };

      const handleSearch1 = (value, cb) => {
        // just to explian API call
        let tempOptions = [...offerArr];
        tempOptions = tempOptions.filter((item) =>
          item["name"]?.toLowerCase().includes(value.toLowerCase())
        );
        // setOptions
        cb(tempOptions);
      };
    

    

 

   
  return (
    <div style={{width:'90%',marginTop:30}}>

      {isloading && <Loadingmy/>}

        {id ? id==state5?.filter((val)=>val.txnid==id)[0]?.txnid ?null: <Loadingmy/>:null}
        
        
        {/* <InputSelectcom data={myoffer} name='txnname' dvalue={mydta.txnname}  onChange={handlemyNew} label={'TXN Name'} placeholder={'Please Enter TXN Name'} /> */}

        <SimpleinpuSelect 
        options={offerArr}
        noOptionText={"No Match Found"}
        onSearch={handleSearch1}
        optionKey={"name"}
        optionCount={5}
        onSelect={handlemyNew}
        label={'TXN Name'}
        value={mydta.txnname}
        placeholder={'Please Enter TXN Name'}
        />

        {/* <SimpleinpuSelect 
        options={idArr}
        noOptionText={"No Match Found"}
        onSearch={handleSearch}
        optionKey={"id"}
        optionCount={5}
        onSelect={handleChnageny}
        label={'User ID'}
        value={mydta.userid}
        placeholder={'Please Enter User ID'}
        /> */}

        
<Inputcom name='userid' value={mydta.userid} onChange={handleChnage} label={'UserId'} placeholder={'Please Enter Userid'} />

        {/* <InputSelectcom name='userid'  onChange={handleChnage} dvalue={mydta.userid} data={myid} label={'User ID'} placeholder={'Please Enter User ID'} /> */}


        <Inputcom name='amount' value={mydta.amount} onChange={handleChnage} label={'TXN Amount'} placeholder={'Please Enter TXN Amount'} />
        <Selectcom name='type' value={mydta.type} onChange={handleChnage}label={'TXN Type'} data={typedata} />
        
        <Selectcom disabled={id && state5[0]?.status=="Successful"?true:false} name='status' value={mydta.status} onChange={handleChnage} label={'TXN Status'} data={statusdata} />
        
       
       {notidata.show?<Popupnoti dispatchfun={mydta.type=="Paid"? fetchwitxn:fetchtxn} show={notidata.show} msg={notidata.msg} success={notidata.success} direction={mydta.type=="Paid"?'/withhistory':'/pendingtxnhistory'}/>:null}
        

        



        
        

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlclk} color={'white'} title={id?'UPDATE TXN':'ADD TXN'} icon={id?faRefresh:faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>


        

    
    </div>
  )
}

export default Addtxn