
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Deletenoti from '../Deletenoti';
import gpeapi from '../../api/gpeapi';
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from '../Pagination';
import { Spinner } from 'react-rainbow-components';
import { useDispatch } from 'react-redux';
import { fethclicks } from '../../redux/actions/clickaction';
import Paginationnew from '../Paginationnew';



const Clickstable = ({ options, data, loading, paginattee }) => {

// console.log(data)
  const token = localStorage.getItem('token')
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [loadingmmm, setloadingmmm] = useState(false)

  useEffect(()=>{
setloadingmmm(loading)
  },[loading])

  // const [currentPage, setCureentPage] = useState(1)
  // const [userPerPage, setuserPerPage] = useState(30)
  // const [myfilter, setmyfilter] = useState([])


  // const indexLastUser = currentPage * userPerPage;
  // const indexFirstUser = indexLastUser - userPerPage;
  // const currentuser = data.slice(indexFirstUser, indexLastUser)


  // useEffect(() => {
  //   setmyfilter(data)
  // }, [data])




  // const paginate = async (number) => {
  //   setloadingmmm(true)
  //   await dispatch(fethclicks(number - 1, 15))
  //   setloadingmmm(false)
  // }

  const paginate =async (number) => {
    paginattee(number,15)
  }


  const getdatevia = (date) => {
    const mydate = new Date(date)
    const todate = mydate.getDate()
    const month = mydate.getMonth()
    const year = mydate.getFullYear()

    return `${todate}-${month}-${year}`
  }

  const getpmamvia = (date) => {
    const mydate = new Date(date)
    var hours = mydate.getHours();
    var minutes = mydate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime
  }

 

  return (
    <div className='container'>
      <br />

      <div className='d-flex justify-content-between'>



        {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




      </div>
      <br />



      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><strong>CLID</strong></TableCell>
              <TableCell ><strong>UserID</strong></TableCell>
              <TableCell><strong>Store </strong></TableCell>
              <TableCell><strong>From</strong></TableCell>
              <TableCell><strong>Date</strong></TableCell>
              <TableCell><strong>CLICKID</strong></TableCell>


            </TableRow>
          </TableHead>

          {
            loadingmmm ? (
              <div style={{ margin: '100px 0px' }}>
                <Spinner size="medium" type="arc" variant="brand" />
              </div>
            ) : (
              <TableBody>
                {data && data?.offerlist?.docs?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.userid}
                    </TableCell>

                    <TableCell >{row.storename}</TableCell>
                    <TableCell >{row?.from}</TableCell>

                    <TableCell style={{ display: 'block', justifyContent: 'space-evenly', border: 0 }}>
                      <p>{getdatevia(row.createdAt)}</p>
                      <p style={{ color: 'gray' }}>{getpmamvia(row.createdAt)}</p>

                    </TableCell>

                    <TableCell style={{ fontSize: 10 }}>{row.clickid}</TableCell>

                    {/* <TableCell  style={{display:'flex',justifyContent:'space-evenly',border:0}}>
                    <FontAwesomeIcon className='usertablicon'  color='yellowgreen' icon={faEye}  />
                    <FontAwesomeIcon className='usertablicon' color='#2a2185' onClick={()=>handleditclk(row.id)} icon={faEdit} />
                    <FontAwesomeIcon className='usertablicon' color='red' onClick={()=>handledeletclk(row.id)} icon={faTrash} />
                   
                    </TableCell> */}





                  </TableRow>
                ))}
              </TableBody>
            )
          }

        </Table>
      </TableContainer>

      <Paginationnew perPage={data?.offerlist?.limit} totalData={data?.offerlist?.total} paginate={paginate} currentPage={data?.offerlist?.page} />

    </div>
  )
}

export default Clickstable