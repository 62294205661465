import { ADD_TXN, SET_BANNERS, SET_BASIC } from "../actiontypes/usertypes";

export const productsInitialState = [];

const basicReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_BASIC:
        return [...action.payload];

        // case ADD_TXN:
        //     return [action.payload]

            // case SET_PERUSER:
            // return [action.payload]
      default:
        return state;
    }
  };
  
  export default basicReducer;


