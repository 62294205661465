import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-rainbow-components'
import { useSelector } from 'react-redux'
import gpeapi from '../../../api/gpeapi'
import Selectcom from '../../../Components/Selectcom'
import Selectnew from '../../../Components/Selectnew'
import Offerchartcom from '../../../Components/Shortcom/Offerchartcom'

const Mainoffchart = () => {
   
    const token = localStorage.getItem('token')

    const rangedata = [
        {value:0,label:'Today'},
        {value:1,label:'Yesterday'},
        {value:7,label:'Last 7 Days'},
        {value:30,label:'Last 30 Days'},
        {value:60,label:'Last 60 Days'},
        {value:365,label:'Last 365 Days'},
        {value:999999,label:'Lifetime'},
    ]

    const [daterange,setdaterange] = useState(0)
    const [enddaterange,setenddaterange] = useState(0)
    const [loading,setloading] = useState(false)

    const [mydata,setmydata] = useState([])

    useEffect(()=>{
      fetchOffersCountAPi()
    },[daterange])

    const fetchOffersCountAPi =async ()=>{
      const bodaydta = {
        startdate:daterange,
        enddate:enddaterange
      }
      setloading(true)
      const res = await gpeapi.post('/getofferchart',bodaydta,{headers:{
        "Authorization":`Beaer ${token}`
    }}).then((ress)=>setmydata(ress?.data?.Offerdta)).catch((e)=>setmydata({name:'No Data Found Err'}))
    setloading(false)
    }




  return (
    <div className='container'>

        <div >

            <div>
                <Selectnew data={rangedata} label={'Please Select '} value={daterange} onChange={(e)=>{
                  setdaterange(e.target.value)
                  if(e.target.value==1){
                    setenddaterange(1)
                  }else{
                    setenddaterange(0)
                  }
                }}/>
            </div>
            

            <div>
              {loading && <Spinner size="small" type="arc" variant="brand" />}
            </div>

           <div style={{display:'flex',flexWrap:'wrap'}}>
           {
              mydata && mydata?.sort((a,b)=>b.count-a.count).sort((a,b)=>b.isactive-a.isactive).map((val,i)=>(
                <Offerchartcom key={i} data={val}/>
               )) 
            }
           </div>
            





        </div>

        {/* <Offerchartcom/> */}

    </div>
  )
}

export default Mainoffchart