import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck, faClock, faBan } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import gpeapi from '../api/gpeapi';
import Deletenoti from './Deletenoti';
import Popupnoti from './Popupnoti';
import { fetchtxn } from '../redux/actions/txnaction';
import { fetchwitxn } from '../redux/actions/witxnaction';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from './Pagination';
import Selectcom from './Selectcom';
import ReactLoading from "react-loading";
import { Spinner } from 'react-rainbow-components';
import Paginationnew from './Paginationnew';
import { fetchpendingtxn } from '../redux/actions/txnpendingaction';
import Txndatatablecard from './Txndatatablecard';


const Txndatatablecom = ({ options, data, index, ispending, mypaginatefun, setloadingdd, mainmyloading }) => {

  const token = localStorage.getItem('token')






  const [loading, setloading] = useState({
    mid: '',
    load: false
  })

  const dispatch = useDispatch()
  // console.log(window.location.pathname==='/pendingtxnhistory')

  const [notidata, setnotidata] = useState({
    msg: '',
    success: '',
    show: ''
  })

  const txnalldata = useSelector((state) => state.txnReducer)
  const useralldata = useSelector((state) => state.userReducer)





  const handlestatuchnage = async (e, id, userid, amt, types, txname) => {
    const data = {
      txnname: txname,
      userid: userid,
      amount: amt,
      type: types,
      status: e
    }
    setloading({ mid: id, load: true })
    // console.log(data)
    await gpeapi.put(`/updatetxnhistory/${id}`, data, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then(async (res) => {
      await dispatch(fetchtxn(0, 15, '', ''))
      dispatch(fetchwitxn(0, 15))
      dispatch(fetchpendingtxn(0, 15))
    }).catch((e) => alert('some error'))
    setloading({ mid: id, load: false })

  }

  const navigate = useNavigate()

  const convertDate = (date) => {
    const mydta = new Date(date);
    const year = mydta.getFullYear();
    const month = mydta.getMonth();
    const day = mydta.getDate()
    const gday = day < 10 ? `0${day}` : day

    return `${gday}-${month + 1}-${year}`
  }


  const getpmamvia = (date) => {
    const mydate = new Date(date)
    var hours = mydate.getHours();
    var minutes = mydate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime
  }


  const handleEdit = (id) => {
    navigate(`/updatetxn/${id}`)
    // navigate(0)
  }



  const handledeletclk = (id) => {
    const onMyclk = () => {
      gpeapi.delete(`/deletetxn/${id}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }
      ).then((res) => res.data).catch((e) => console.log(e))
    }
    Deletenoti({ text: `${id} TXNID will Delete in your System`, onClick: onMyclk })
  }

  // console.log(mysts)

  const checkcopy = async (id, offname) => {
    // const filldata = await txnalldata.filter((val)=>val.userid==id && val.txnname==offname)
    // if(filldata.length>1){
    //   return "Copy"
    // }
    // else{
    //   return "f"
    // }

    return id
  }


  const [currentPage, setCureentPage] = useState(1)
  const [userPerPage, setuserPerPage] = useState(30)
  const [myfilter, setmyfilter] = useState([])



  // const indexLastUser = currentPage * userPerPage;
  // const indexFirstUser = indexLastUser - userPerPage;
  // const currentuser = data?.slice(indexFirstUser, indexLastUser)

  const [loadingmmm, setloadingmmm] = useState(false)

  useEffect(() => {
    setloadingmmm(mainmyloading)
  }, [mainmyloading])

  // useEffect(() => {
  //   setmyfilter(data)
  // }, [data])

  const paginate = async (number) => {
    // setloadingmmm(true)
    // await dispatch(fetchtxn(number-1,15,'',''))
    // setloadingmmm(false)
    mypaginatefun(number)
  }


  const checkfilternumber = (id, name) => {

    const fildata = txnalldata?.TotalUsers?.docs?.filter((val) => val.userid == id && val.txnname == name)

    return fildata?.length

    // console.log(res.data)
  }

  const checkfilternumberuid = (id, name) => {
    const fildata = txnalldata?.TotalUsers?.docs.filter((val) => val?.deviceuid && val?.deviceuid == id && val.txnname == name)

    if (fildata?.length) {
      return fildata?.length

    } else {
      return 0
    }

  }

  const handleviewclick = (id) => {
    navigate(`/viewuser/${id}`)
  }


  const checkmobile = (userid) => {
    const fildta = useralldata?.TotalUsers?.docs.filter((val) => val?.id == userid)[0]
    return fildta?.mobile
  }


  const checkfilternumbermyy = async (idd, tcxn) => {
    console.log(idd)
    return 555
  }




  return (
    <div className='container'>
      <br />

      <div className='d-flex justify-content-between'>



        {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




      </div>
      <br />



      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>


              {index ? <TableCell><strong>#</strong></TableCell> : null}
              {/* <TableCell><strong>SCLT</strong></TableCell> */}
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>UserID</strong></TableCell>
              <TableCell ><strong>TXN Name</strong></TableCell>
              {!index && <TableCell ><strong>DUID</strong></TableCell>}
              <TableCell ><strong>TXN ID</strong></TableCell>
              <TableCell ><strong>Type</strong></TableCell>
              {index ? <TableCell><strong>PayMode</strong></TableCell> : null}
              {index ? <TableCell><strong>Payout AC</strong></TableCell> : null}
              {/* {index ? <TableCell><strong>UPIID</strong></TableCell> : null} */}
              <TableCell><strong>TXN Amt</strong></TableCell>
              <TableCell><strong>Chng</strong></TableCell>
              <TableCell ><strong>Status</strong></TableCell>
              <TableCell ><strong>Date</strong></TableCell>

              <TableCell align='right'><strong>Change</strong></TableCell>


            </TableRow>
          </TableHead>

          {
            loadingmmm ?
              <div style={{ margin: '100px 0px' }}>
                <Spinner size="medium" type="arc" variant="brand" />
              </div>
              :

              <TableBody>

                {data && data?.TotalUsers?.docs?.map((row, i, arr) => (
                  <Txndatatablecard data={data} row={row} index={index} i={i} />
                ))}

              </TableBody>

            // <TableBody >
            //   {data && data?.TotalUsers?.docs?.map((row, i) => (


            //     <TableRow
            //       key={i}
            //       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            //       className='ontablehover'
            //     >

            //       {index ? <TableCell >{data?.TotalUsers?.docs?.length - i}</TableCell> : null}
            //       {/* <TableCell >
            //   <input type={'checkbox'} onChange={(e)=>console.log(e.target.value)}/>

            //   </TableCell> */}
            //       <TableCell component="th" scope="row">
            //         {row.id}
            //       </TableCell>
            //       <TableCell >{row.userid}</TableCell>

            //       <TableCell >{row.txnname}   <span style={{ backgroundColor: '#484e54', padding: 2, color: 'white', fontSize: 15, borderRadius: 3 }}>{checkfilternumber(row.userid, row.txnname)}</span> </TableCell>

            //       {!index && <TableCell style={{ display: 'flex' }}>{row?.deviceuid}   <span style={{ backgroundColor: '#484e54', padding: 2, color: 'white', fontSize: 15, borderRadius: 3 }}>{checkfilternumberuid(row?.deviceuid, row.txnname)}</span> </TableCell>}

            //       <TableCell >{row.txnid}</TableCell>

            //       <TableCell >{row.type}</TableCell>
            //       {index ? <TableCell >{row.mobile ? row.mobile : checkmobile(row.userid)}</TableCell> : null}
            //       <TableCell style={{ fontWeight: 'bold', color: '#2a2185' }}>{row.amount}<FontAwesomeIcon icon={faIndianRupeeSign} fontSize={11} /></TableCell>

            //       <TableCell>

            //         <select disabled={row.status == 'Successful'} value={row.status} onChange={(e) => handlestatuchnage(e.target.value, row.txnid, row.userid, row.amount, row.type, row.txnname)}>
            //           <option value={'Successful'}>Successful</option>
            //           <option value={'Pending'}>Pending</option>
            //           <option value={'Rejected'}>Rejected</option>
            //         </select>
            //       </TableCell>



            //       <TableCell >{row.status == 'Successful' ? <FontAwesomeIcon icon={faCircleCheck} color={'	#42ba96'} /> : row.status == "Pending" ? <FontAwesomeIcon icon={faClock} color={'#bfb60d'} /> : <FontAwesomeIcon icon={faBan} color={'red'} />}  {loading.load && loading.mid == row.txnid && <ReactLoading height={5} width={15} color='red' type='spin' />}  </TableCell>

            //       {/* <TableCell>

            //   <select disabled={row.status=='Successful'} value={row.status} onChange={(e)=>handlestatuchnage(e.target.value,row.id)}>
            //     <option value={'Successful'}>Successful</option>
            //     <option value={'Pending'}>Pending</option>
            //     <option value={'Rejected'}>Rejected</option>
            //   </select>
            //   </TableCell> */}

            //       {/* <TableCell >{convertDate(row.createdAt)}</TableCell> */}

            //       <TableCell >
            //         <p>{convertDate(row.createdAt)}</p>
            //         <p style={{ color: 'gray' }}>{getpmamvia(row.createdAt)}</p>

            //       </TableCell>


            //       <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', border: 0 }}>
            //         <FontAwesomeIcon className='usertablicon' color='yellowgreen' icon={faEye} onClick={() => handleviewclick(row.userid)} />
            //         <FontAwesomeIcon className='usertablicon' color='#2a2185' icon={faEdit} onClick={() => handleEdit(row.txnid)} />
            //         <FontAwesomeIcon className='usertablicon' color='red' onClick={() => handledeletclk(row.id)} icon={faTrash} />

            //       </TableCell>

            //       {notidata.show ? <Popupnoti setshow={setnotidata} dispatchfun={row.type == "Paid" ? fetchwitxn : fetchtxn} show={notidata.show} msg={notidata.msg} success={notidata.success} direction={row.type == "Paid" ? '/withhistory' : row.type == 'Pending' ? '/pendingtxnhistory' : '/txnhistory'} /> : null}






            //     </TableRow>
            //   ))}
            // </TableBody>
          }

        </Table>
      </TableContainer>


      {
        ispending ? null : <Paginationnew perPage={data?.TotalUsers?.limit} totalData={data?.TotalUsers?.total} paginate={paginate} currentPage={data?.TotalUsers?.page} />




      }





    </div>
  )
}

export default Txndatatablecom