import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import {useDispatch,useSelector} from 'react-redux'
import { fethselectedbanner, fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethbanners } from '../../redux/actions/banneraction'


const Addbanner = () => {

  const {id} = useParams()

  const dispatch = useDispatch()

  const state5 = useSelector((state)=>state.selectedReducer)

  const token = localStorage.getItem('token')

const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    name:'',
    img:'',
    url:'',
    isactive:'',
    type:''
    
})

const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}






const callingAPI = ()=>{
  dispatch(fethselectedbanner(id))
}

const updateForm = async ()=>{
        
  const userData = await state5.filter((val)=>val.id==id)
  
  userData.map((val)=>setmydta(val))

 
 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}


const handlBtnClk = ()=>{
   

    id?gpeapi.put(`/updatebanner/${id}`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    :gpeapi.post('/addbanner',mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))

  // console.log(mydta)
  }









// console.log(state5[0])
const match = state5[0]?.id==id

useEffect(()=>{
  id && callingAPI()
  updateForm()
},[match,id])






  return (
    <div style={{width:'90%',marginTop:30}}>



        <Inputcom label={'Banner Name'} placeholder={'Please Enter Banner Name'} name='name' value={mydta.name} onChange={handleChnage}  />
      
        <Inputcom label={'Banner Url'} placeholder={'Please Enter Banner URL'} name='url' value={mydta.url} onChange={handleChnage}/>
        <Inputcom label={'Banner IMG Url'} placeholder={'Please Enter Banner IMG URL'} name='img' value={mydta.img} onChange={handleChnage}/>
        {<div className='flex justify-center  '>
          <img className='bg-red-200 rounded-md' src={mydta.img?mydta.img:'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{width:300 , height:100,padding:3}}/>
          </div>}
        {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}
        
        {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

        <Selectcom label={'Type For'} data={['HOME','COUPON']} name='type' onChange={handleChnage} value={mydta.type}/>
        
        <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/>

        

        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/banners'} dispatchfun={fethbanners}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE BANNER':'ADD BANNER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>

        


        



    </div>
  )
}

export default Addbanner


