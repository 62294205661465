import React from 'react'
import { useEffect } from 'react';



const Paginationnew = ({ perPage, totalData, paginate,currentPage }) => {



  const pageNumber = []

  for (let i = 0; i <= Math.ceil(totalData / perPage)-1; i++) {
    pageNumber.push(i)
  }



 


  const myshownum = []
  const firstshow = myshownum.push(...pageNumber.filter((val)=>val<=currentPage).slice(-6),...pageNumber.filter((val)=>currentPage<val).slice(0,5))

  


  return (
    <div style={{margin:'10px 0px',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
        <ul className='pagination' style={{display:'flex',flexWrap:'wrap'}}>
          
          {
            currentPage==pageNumber[0]==false && <li className="page-item" style={{marginRight:35}}>
            <a className='page-link' onClick={()=>paginate(0)}>0</a>
            </li>
          }

            {myshownum.map((number)=>(
                <li key={number} className="page-item">
                <a style={{backgroundColor:number==currentPage && '#e65b32',color:number==currentPage && 'white'}} className='page-link' onClick={()=>paginate(number)}>{number}</a>

                


               </li>
            ))}
            {
              currentPage==pageNumber[pageNumber.length-1]==false  &&  <li className="page-item" style={{marginLeft:35}}>
              <a className='page-link' onClick={()=>paginate(pageNumber[pageNumber.length-1])}>{pageNumber[pageNumber.length-1]}</a>
              </li>
            }


        </ul>
    </div>


 


  )
}

export default Paginationnew

