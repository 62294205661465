


import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import gpeapi from '../../api/gpeapi';
import Deletenoti from '../Deletenoti';
import Pagination from '../Pagination';

const Scratchcardtable = ({ options, data }) => {
    const token = localStorage.getItem('token')

    const navigate = useNavigate()

    const handleditclk = (id) => {
        navigate(`/updatescratchcard/${id}`)
    }

    const handleuserid = (id) => {
        navigate(`/viewuser/${id}`)


    }

    const handleview = (id) => {
        // navigate(`/viewpmcode/${id}`)
    }

    const handledeletclk = (id) => {
        const onMyclk = () => {
            gpeapi.delete(`/deletescratchcard/${id}`, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }
            ).then((res) => res.data).catch((e) => console.log(e))
        }
        Deletenoti({ text: `${id} Scarcth will Delete in your System`, onClick: onMyclk })
    }

    const [currentPage, setCureentPage] = useState(1)
    const [userPerPage, setuserPerPage] = useState(15)
    const [myfilter, setmyfilter] = useState([])


    const indexLastUser = currentPage * userPerPage;
    const indexFirstUser = indexLastUser - userPerPage;
    const currentuser = data.slice(indexFirstUser, indexLastUser)


    useEffect(() => {
        setmyfilter(data)
    }, [data])

    const paginate = (number) => {
        setCureentPage(number)
    }

    const convertDate = (date) => {
        const mydta = new Date(date);
        const year = mydta.getFullYear();
        const month = mydta.getMonth();
        const day = mydta.getDate()
        const gday = day < 10 ? `0${day}` : day
        var hours = mydta.getHours();
        var minutes = mydta.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        return `${gday}-${month + 1}-${year} ${strTime}`
    }



    return (
        <div className='container'>
            <br />

            <div className='d-flex justify-content-between'>



                {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




            </div>
            <br />



            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>ID</strong></TableCell>
                            <TableCell><strong>UserId</strong></TableCell>
                            <TableCell ><strong>AMT</strong></TableCell>

                            <TableCell ><strong>isclaimed</strong></TableCell>
                            <TableCell ><strong>isexpired</strong></TableCell>
                            <TableCell ><strong>Date</strong></TableCell>
                            <TableCell align='right'><strong>Change</strong></TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentuser && currentuser.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell style={{ color: 'blue',cursor:'pointer'
                                }} onClick={() => handleuserid(row.userid)}>{row.userid}</TableCell>
                                <TableCell >{row.amount}₹</TableCell>



                                <TableCell >{row.isclaimed ? <FontAwesomeIcon icon={faCircleCheck} color={'#2a2185'} /> : <FontAwesomeIcon icon={faXmark} color={'red'} />}</TableCell>
                                <TableCell >{row.isexpired ? <FontAwesomeIcon icon={faCircleCheck} color={'#2a2185'} /> : <FontAwesomeIcon icon={faXmark} color={'red'} />}</TableCell>
                                <TableCell >{convertDate(row.createdAt)}</TableCell>

                                <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', border: 0 }}>
                                    <FontAwesomeIcon className='usertablicon' color='yellowgreen' icon={faEye} onClick={() => handleview(row.id)} />
                                    <FontAwesomeIcon className='usertablicon' color='#2a2185' onClick={() => handleditclk(row.id)} icon={faEdit} />
                                    <FontAwesomeIcon className='usertablicon' color='red' onClick={() => handledeletclk(row.id)} icon={faTrash} />

                                </TableCell>





                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination perPage={userPerPage} totalData={data.length} paginate={paginate} currentPage={currentPage} />

        </div>
    )
}

export default Scratchcardtable

