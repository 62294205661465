import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Btnwithicon from '../../Components/Btnwithicon'
import Popupnoti from '../../Components/Popupnoti'
import Txndatatablecom from '../../Components/Txndatatablecom'

import Deletenoti from '../../Components/Deletenoti'
import Pagination from '../../Components/Pagination'
import Mybtn from '../../Components/Mybtn'
import { useEffect } from 'react'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { CSVLink } from 'react-csv'
import { fetchtxn } from '../../redux/actions/txnaction'
import { fetchwitxn } from '../../redux/actions/witxnaction'
import { Spinner } from 'react-rainbow-components'






const Mainwithdrwa = () => {


  const withdrwatxnData = useSelector((state) => state.witxnReducer)
  const [myfilter, setmyfilter] = useState([])

  const [currentPage, setCureentPage] = useState(1)
  const [userPerPage, setuserPerPage] = useState(30)

  const indexLastUser = currentPage * userPerPage;
  const indexFirstUser = indexLastUser - userPerPage;
  // let currentuser = myfilter?.slice(indexFirstUser, indexLastUser)


  
  useEffect(() => {
    setmyfilter(withdrwatxnData)
  }, [withdrwatxnData])

  // console.log(myfilter)


  const paginate = (number) => {
    setCureentPage(number)
  }


  const [status, setstatus] = useState('')


  // console.log(myfilter)


  const handleserchdata = () => {
    const fillterdviaStatus = withdrwatxnData?.filter((val) => status == 'all' ? val : val.status == status)
    setmyfilter(fillterdviaStatus)
  }


  const _exporter = React.createRef();

  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };




  const headers = [
    {label:"User's Mobile Number/Email",key:'mobile'},
    {label:"Beneficiary Cust ID(Optional)",key:'nnn'},
    {label:"Amount",key:'amount'},
    {label:"Beneficiary Name",key:'mobile'},
    {label:"Comment",key:'hhh'},

  ]

  const dispatch = useDispatch()
  const [referesloading,setreferesloading] = useState(false)

  const reloadAction =async ()=>{
    setreferesloading(true)
    await dispatch(fetchwitxn(0,15))
    setreferesloading(false)

  }

  const [loading,setloading] = useState(false)


  const paginatefun = async(num)=>{
    setloading(true)
    await dispatch(fetchwitxn(num,15))
    setloading(false)
  

  }


  return (
    <div>

      <div>
        <div>

          <select value={status} onChange={(e) => setstatus(e.target.value)}>
            <option value="" selected disabled hidden>Select here</option>
            <option value={'all'}>All</option>
            <option value={'Pending'}>Pending</option>
            <option value={'Rejected'}>Rejected</option>
            <option value={'Successful'}>Successful</option>

          </select>

          <Mybtn title={'GET DATA'} bgcolor={'#e65b32'} color={'white'} onClick={handleserchdata} />

        </div>

        {/* <div>

          <Mybtn title={'donw exel'} onClick={excelExport}/>
        <ExcelExport data={currentuser}  collapsible={true} fileName={`withdrwalist${new Date().toDateString()}.csv`} ref={_exporter}  >
          <ExcelExportColumn field="mobile" title="User's Mobile Number/Email" width={200} />
          <ExcelExportColumn field="userid" title="Beneficiary Cust ID(Optional)" />
          <ExcelExportColumn field="amount" title="Amount"  />
          <ExcelExportColumn field="userid" title="Beneficiary Name" />
          <ExcelExportColumn field='txnid'    title="Comment"  />
   
          
        </ExcelExport>
        </div> */}

        
          <p style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <CSVLink 
          filename={`withdrwalist${new Date().toDateString()}.csv`}
          data={withdrwatxnData?.TotalUsers?.docs}
          headers={headers}
          
          >
          <Mybtn title={'Download CSV'} />

          </CSVLink>
          </p>
        



      </div>

      <Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />

      



      <Txndatatablecom data={withdrwatxnData} index={true} mypaginatefun={paginatefun} mainmyloading={loading}/>

      {/* <Pagination totalData={withdrwatxnData.length} perPage={userPerPage} paginate={paginate} /> */}


      {/* <button onClick={handlclkk}>clck</button> */}

      {/* <Btnwithicon title={'CLick'} onClick={() => setisopen(true)} /> */}
      {/* <Popupnoti title={'OK'} success={true} msg={'Satting Saved'} show={isopen}/> */}
      {/* {Popupnoti({ show: isopen, title: 'OK', success: true, msg: "Hello " })} */}
    </div>
  )
}

export default Mainwithdrwa