import React from 'react'
import { useNavigate } from 'react-router-dom'
import Btnwithicon from '../../Components/Btnwithicon'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Promotable from '../../Components/Tablesdata/Promotable';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-rainbow-components';
import { fethPromocode } from '../../redux/actions/promoaction';
import { useState } from 'react';
import Mybtn from '../../Components/Mybtn';


const Mainpmcode = () => {

  const promoData = useSelector((state) => state.promoReducer)


    const navigate = useNavigate()

    const AddofferBtnClk = () => {
      navigate('/addpmcode')
  
    }

    const dispatch = useDispatch()
    const [referesloading,setreferesloading] = useState(false)
  
    const reloadAction =async ()=>{
      setreferesloading(true)
      await dispatch(fethPromocode())
      setreferesloading(false)
  
    }
  return (
    <div>

<div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

<Btnwithicon color={'#2a2185'} title={'ADD PROMOCODE'} icon={faCirclePlus} onClick={AddofferBtnClk} />

<Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />


</div>

<Promotable data={promoData}/>
    </div>
  )
}

export default Mainpmcode