import React from 'react'
import { useState } from 'react'
import gpeapi from '../../api/gpeapi'
import ReactLoading from 'react-loading';
import Mybtn from '../../Components/Mybtn';
import Btnwithicon from '../../Components/Btnwithicon';

const Loginform = () => {

    const [loading,setloading] = useState(false)


    const [data,setdata] = useState({
        mobile:'',
        password:''
    })

    const handlechange = (e)=>{
        const name = e.target.name;
        const value = e.target.value
        setdata({...data,[name]:value})
    }



    const loginresponse =async (res)=>{
        
        if(res.user.role=='admin'){
            await localStorage.setItem('token',res.token);
            await localStorage.setItem('userInfo',JSON.stringify(res.user));
            window.location.reload()
            alert(`Welcome ${res.user.name}`)

        }else{
            alert('you are not a admin')
        }
        
    }





    const handlelogin = async ()=>{
       await setloading(true)
         if(data.mobile!=='' && data.password !==''){
           
            gpeapi.post('/login',data).then((res)=>loginresponse(res.data)).catch((e)=>{
                
                alert(e.response?.data.msg)
                
                
             })
             
         }else{
            alert('Please Fill All Data')
         }
         setloading(false)
         
         
            
    }


  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
  <div className="w-full max-w-md space-y-8">
    <div>
      <img className="mx-auto h-12 w-auto" src="https://res.cloudinary.com/dyueow7yj/image/upload/v1663911731/gwalaxpelogo_lfqeos.png" alt="Gwalaxpe"/>
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
      
    </div>
    
      <input type="hidden" name="remember" value="true"/>
      <div className="-space-y-px rounded-md shadow-sm m-2">
        <div className='m-2'>
          <label htmlFor="email-address" className="sr-only">Mobile Number</label>
          <input value={data.mobile} onChange={handlechange} id="email-address" name="mobile" type="number"  required className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Mobile Number"/>
        </div>
        <div className='m-2'>
          <label htmlFor="password" className="sr-only">Password</label>
          <input value={data.password} onChange={handlechange} id="password" name="password" type="password"  required className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Password"/>
        </div>
      </div>

      {/* <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
          <label for="remember-me" className="ml-2 block text-sm text-gray-900">Remember me</label>
        </div>

        <div className="text-sm">
          <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password?</a>
        </div>
      </div> */}



      <div>
        {/* <button onClick={handlelogin} className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
           
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
            </svg>
          </span>
          Sign in
        </button> */}

        <Mybtn loading={loading} title={'LOGIN'} bgcolor={'#e65b32'} color={'white'} width={'100%'} onClick={handlelogin}/>
       
      </div>
    
  </div>

  {/* {loading && <ReactLoading type='spin' color='#e65b32' />} */}
</div>
  )
}

export default Loginform