import gpeapi from "../../api/gpeapi";
import { SET_TXN, SET_WITXN } from "../actiontypes/usertypes";


const token = localStorage.getItem('token')



export const fetchwitxn = (page,limit)=>{

    return async function(dispatch){

        const res = await gpeapi.get(`/getwithdrwatxnviapagi?page=${page}&limit=${limit}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        const fillterdtoNew = res?.data

        dispatch(setWitxn(fillterdtoNew));

    }
}








export const setWitxn = (data = null) => {
    if (data) {
        return {
            type: SET_WITXN,
            payload: data,
        };
    }

    return {
        type: SET_WITXN,
        payload: [],
    };
};
