import gpeapi from "../../api/gpeapi";
import {SET_IPLMATCHES, SET_IPLTEAMS } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')

export const fethiplmatches = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/getiplmatches',{headers:{
            "Authorization":`Beaer ${token}`
        }})

      
        const fillterdtoNew = res.data?.mydta?.sort((a,b)=>b.id-a.id)


        dispatch(setIplMatches(fillterdtoNew));
       
    }
}



export const setIplMatches = (data = null) => {
    if (data) {
        return {
            type: SET_IPLMATCHES,
            payload: data,
        };
    }

    return {
        type: SET_IPLMATCHES,
        payload: [],
    };
};

