import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Button, Spinner } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Btnwithicon from '../../../Components/Btnwithicon';
import Iplteamtable from '../../../Components/Tablesdata/Iplteamtable';
import Iplpredicttable from '../../../Components/Tablesdata/Iplpredicttable';
import { fethiplpredicts } from '../../../redux/actions/iplpredictaction';
import Mybtn from '../../../Components/Mybtn';


const Mainiplpredicts = () => {

  const navigate = useNavigate()

  const offerData = useSelector((state) => state.iplpredictReducer)

  const AddofferBtnClk = () => {
    navigate('/addiplpredicts')

  }

  const [findid, setfindid] = useState('')

 
  
  const filldata = offerData.filter((x) => {
    return findid !== "" ? x.id.toString().includes(findid) || x.amount.toString().includes(findid) || x.name.toLowerCase().includes(findid) || x.name.includes(findid) : x
  })

  const dispatch = useDispatch()
  const [referesloading,setreferesloading] = useState(false)

  const reloadAction =async ()=>{
    setreferesloading(true)
    await dispatch(fethiplpredicts())
    setreferesloading(false)

  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        <Btnwithicon color={'#2a2185'} title={'ADD OFFER'} icon={faCirclePlus} onClick={AddofferBtnClk} />
        <Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />

      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

      </div>
      {/* <OfferdataTable data={filldata} /> */}

      <Iplpredicttable data={filldata}/>



    </div>
  )
}

export default Mainiplpredicts

