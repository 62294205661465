import { ADD_TXN, SET_PENDINGTXN, SET_PERUSER, SET_TXN, SET_WITXN } from "../actiontypes/usertypes";

export const productsInitialState = [];

const txnpendingReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_PENDINGTXN:
        return {...action.payload};

       

            // case SET_PERUSER:
            // return [action.payload]
      default:
        return state;
    }
  };
  
  export default txnpendingReducer;