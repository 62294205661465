import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../Components/Pagination'
import Calendarmy from '../../Components/Shortcom/Calendarmy'

import Txndatatablecom from '../../Components/Txndatatablecom'
import { Spinner } from 'react-rainbow-components'
import Btnwithicon from '../../Components/Btnwithicon'
import { fetchtxn } from '../../redux/actions/txnaction'
import Mybtn from '../../Components/Mybtn'
import { useEffect } from 'react'

const Maintxn = () => {

  const txnData = useSelector((state) => state.txnReducer)

  const txnsepedataData = txnData?.TotalUsers?.docs






  const [startDate, setStartDate] = useState(null)
  const mydate = new Date(startDate)
  const todate = mydate.getDate()
  let month = mydate.getMonth()
  month = month + 1
  const year = mydate.getFullYear()
  const finaldate = `${year}-${month < 10 ? `0${month}` : month}-${todate < 10 ? `0${todate}` : todate}`

  const [findid, setfindid] = useState('')

  // console.log(todate)

  // const filldata = txnData.filter((x) => {
  //   return startDate !==null? x.createdAt?.split('T')[0]===finaldate : findid !== "" ? x.id.toString().includes(findid) || x.userid.toString().includes(findid) || x.amount.toString().includes(findid) || x.txnname.toLowerCase().includes(findid) || x.status.includes(findid) : x
  // })



  const dispatch = useDispatch()
  const [referesloading, setreferesloading] = useState(false)

  const reloadAction = async () => {
    setreferesloading(true)
    await dispatch(fetchtxn(0, 15, '', ''))
    setreferesloading(false)

  }

  const [loading, setloading] = useState(false)


  const paginatefun = async (num) => {
    setloading(true)
    await dispatch(fetchtxn(num, 15, findid, ''))
    setloading(false)


  }

  const [searchLoad, setsearchLoad] = useState(false)
  const [inque,setinque] = useState(false)

  const handleSearch = async () => {
    if (findid !== "") {
      setsearchLoad(true)
      await dispatch(fetchtxn(0, 15, findid, ''))
      setinque(true)
      setsearchLoad(false)

    }
  }

  const handleSearchClose =async ()=>{
    setsearchLoad(true)
      await dispatch(fetchtxn(0, 15, '', ''))
      setfindid('')
      setinque(false)
      setsearchLoad(false)
  }
  



  return (
    <div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

        {searchLoad && <Spinner size="small" type="arc" variant="brand" />}

        <Mybtn  title={'Search'} onClick={handleSearch} />
        {inque && <Mybtn  title={'X'} onClick={handleSearchClose} />}
        


        <div style={{ cursor: 'pointer', zIndex: 8 }}>
          Date
          <Calendarmy value={startDate} onChange={(e) => setStartDate(e)} />
        </div>

      </div>

      <Mybtn color={'#2a2185'} title={referesloading ? <Spinner size="small" type="arc" variant="brand" /> : '↻'} onClick={reloadAction} />

      <Txndatatablecom data={txnData} mypaginatefun={paginatefun} mainmyloading={loading} />

      {/* <Pagination totalData={txnData.length} perPage={userPerPage} paginate={paginate}/> */}


    </div>
  )
}

export default Maintxn