import React from 'react'

const Selectcom = ({label,onChange,name,data,value,disabled}) => {
    return (
        
        <div className='mb-6 mt-6' style={{position:'relative'}}>

        
            <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{label}</label>
            <select defaultValue={value} disabled={disabled}  onChange={onChange} name={name} value={value} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option  value="" selected disabled hidden>Select here</option>
                {data.map((val,i)=>(
                <option key={i}  value={val}>{val}</option>

                ))}



            </select>
            </div>
            
    )
}

export default Selectcom