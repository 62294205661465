import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-rainbow-components';

import Btnwithicon from '../../Components/Btnwithicon';
import Iplteamtable from '../../Components/Tablesdata/Iplteamtable';
import Scratchcardtable from '../../Components/Tablesdata/Scratchcardtable';
import { fetchscratchcard } from '../../redux/actions/scratchcardaction';
import Mybtn from '../../Components/Mybtn';


const Mainscratchcard = () => {

  const navigate = useNavigate()


  const offerData = useSelector((state) => state.scratchReducer)



  var x = offerData
var y = [...x].reverse();
var y = [...x].reverse();


//   console.log(newOffdata)

  const AddofferBtnClk = () => {
    navigate('/addscratchcard')

  }

  const [findid, setfindid] = useState('')
  
  const filldata = y.filter((x) => {
    return findid !== "" ? x.id.toString().includes(findid) || x.userid.toString().includes(findid) || x.amount.toString().includes(findid) || x.txnname.toLowerCase().includes(findid)  : x
  })

  const dispatch = useDispatch()
  const [referesloading,setreferesloading] = useState(false)

  const reloadAction =async ()=>{
    setreferesloading(true)
    await dispatch(fetchscratchcard())
    setreferesloading(false)

  }


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        <Btnwithicon color={'#2a2185'} title={'ADD OFFER'} icon={faCirclePlus} onClick={AddofferBtnClk} />
        
       
        <Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />

        

        
       


      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

      </div>
      {/* <OfferdataTable data={filldata} /> */}

      <Scratchcardtable data={filldata}/>



    </div>
  )
}

export default Mainscratchcard

