import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Btnwithicon from '../../Components/Btnwithicon'
import Inputcom from '../../Components/Inputcom'
import { faCirclePlus,faRefresh } from '@fortawesome/free-solid-svg-icons'
import gpeapi from '../../api/gpeapi'
import NotificationTest from '../../Components/NotificationTest'


const Addnotification = () => {

const token = localStorage.getItem('token')


    



    // console.log(allusertoken)

    const [mydta,setmydta]= useState({
        title:'',
        subtitle:'',
        img:'',
        
        
    })

   

    const handleChnage = (e)=>{
        const value = e.target.value;
        const name = e.target.name;
        setmydta({...mydta,[name]:value})
    }

    const handlclk =async ()=>{

        // const res = await gpeapi.post('/sendmsgtoall',mydta,{headers:{
        //     "Authorization":`Beaer ${token}`
        // }}).then((res)=>{
        //     alert('Notification Sent')
        //     console.log(res.data)
        // }).catch((e)=>alert('Notification Not Sent some Error '))
        // console.log(mydta)
        
        // console.log(res)

        // const msg = {
        //     tokens:['eU4MJM3wQvSFUqzpEgX0O6:APA91bFvUSsSUv1Im8ShjXLHBwvRRKOY00aCzZmBwzBRNbJffnFKYtDptqYEJHsSaao7WAZDo_9_a_zHRVr_INH0blyZhmdBzKmvFI7UUf7f3LcXSNfLUWHLk5FeR3t2ARy9SNkgdi52'],
        //     title:mydta.title,
        //     subtitle:mydta.subtitle
        // }

       const ress = await NotificationTest(mydta)
       alert('Notification Sent Done')
    //    alert(`noti sent total ${ress?.success}`)
       
    }
  return (
    <div>

        <p>Notification Send All Users</p>

<Inputcom name='title' value={mydta.title} onChange={handleChnage} label={'Title'} placeholder={'Please Enter Title'} />

<Inputcom name='subtitle' value={mydta.subtitle} onChange={handleChnage} label={'Subtitle'} placeholder={'Please Enter Subtitle'} />

<Inputcom name='img' value={mydta.img} onChange={handleChnage} label={'Image Add'} placeholder={'Please Enter Image url'} />


<div style={{margin:'60px 0px'}}><Btnwithicon icon={faCirclePlus} onClick={handlclk} color={'white'} title={'SEND NOTIFICATION'}  width={'950px'} bgcolor={'#2a2185'}/></div>


    </div>
  )
}

export default Addnotification