import React from 'react'
import { Button } from 'react-rainbow-components'
import ReactLoading from 'react-loading';


const Mybtn = ({title,icon,onClick,width,bgcolor,color,loading}) => {
  return (
    <div>
      <Button  onClick={onClick} style={{ color: color, borderRadius: 8,fontWeight:'bold',width:width,backgroundColor:bgcolor }}  variant="neutral" className="rainbow-m-around_medium">
            {title}
            {loading&& <ReactLoading className='myreactlod' color='white' width={30} type='spin' />}
        </Button>
    </div>
  )
}

export default Mybtn