import React from 'react'
import Datatable from '../../Components/Datatable'
import Newdatatable from '../../Components/Newdatatable'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Btnwithicon from '../../Components/Btnwithicon'
import { useState } from 'react'
import Pagination from '../../Components/Pagination'
import { useEffect } from 'react'
import DashCard from '../../Components/DashCard'
import Calendarmy from '../../Components/Shortcom/Calendarmy'
import { fethusers } from '../../redux/actions/usersaction'
import { Spinner } from 'react-rainbow-components'
import Mybtn from '../../Components/Mybtn'


const Usermain = () => {

  const testuserData = useSelector((state) => state.userReducer)

  const userData = testuserData?.TotalUsers?.docs

  // console.log(userData)

  const [findid, setfindid] = useState('')
  const [findidonly, setfindidonly] = useState('')


  const [startDate, setStartDate] = useState(null)
  const mydate = new Date(startDate)
  const todate = mydate.getDate()
  let month = mydate.getMonth()
  month = month+1
  const year = mydate.getFullYear()
  const finaldate = `${year}-${month<10?`0${month}`:month}-${todate<10?`0${todate}`:todate}`




  const navigate = useNavigate()

  const AddofferBtnClk = () => {
    navigate('/adduser')

  }





  const callanydate = (days) => {
    var mysevendays = new Date();
    mysevendays.setDate(mysevendays.getDate() - days);
    return mysevendays.toISOString().split('T')[0]
  }


 
  



  // console.log(lastsevendays)

  const dispatch = useDispatch()
  const [referesloading,setreferesloading] = useState(false)
  const [searchloading,setsearchloading] = useState(false)
  const [datasearchshowing,setdatasearchshowing] = useState(false)

  const reloadAction =async ()=>{
    setreferesloading(true)
    await dispatch(fethusers(0,15,findid,findidonly))
    setreferesloading(false)

  }

  const handlesearchclick =async ()=>{
    setsearchloading(true)
    await dispatch(fethusers(0,15,findid,findidonly))
    setsearchloading(false)
    setdatasearchshowing(true)
  }

  const handleclosesearch = async()=>{
    setsearchloading(true)
    await dispatch(fethusers(0,15,'',''))
    setsearchloading(false)
    setdatasearchshowing(false)
    setfindid('')
    setfindidonly('')
  }

  const [loading, setloading] = useState(false)

  const paginatefun = async (num) => {
    setloading(true)
    await dispatch(fethusers(num, 15, findid, findidonly))
    setloading(false)


  }

 



  return (
    <div>
      <div className='cardBox'>

        {
          testuserData?.allNumbers?.map((val,i)=>(

            <DashCard number={val?.value} title={val?.title} icon={'person-outline'} />
          ))
        }
       

      </div>

      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        <Btnwithicon color={'#2a2185'} title={'ADD USER'} icon={faCirclePlus} onClick={AddofferBtnClk} />

        <Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />
      </div>

      <div>

        <div class="search" style={{display:'flex',width:'70%'}}>
          <label>
            <input type="text" value={findidonly}  onChange={(e) => setfindidonly(e.target.value)} placeholder="Search UserID only" />
           

          </label>

          <label>
            <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search User Here" />
           
          </label>

          <Mybtn title={'Check'} onClick={handlesearchclick}/>

        <Mybtn color={'#2a2185'} title={datasearchshowing?'❌':null}  onClick={datasearchshowing?handleclosesearch:null} />


 

          
        <div style={{cursor:'pointer',zIndex:8}}>
          Date
          <Calendarmy value={startDate} onChange={(e)=>setStartDate(e)}/>
        </div>
{/* 
          <label>
            <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search Refer Code" />
           
          </label> */}
        </div>
      </div>

{
  searchloading ? 
  <Spinner size="small" type="arc" variant="brand" />
  : <Datatable mypaginatefun={paginatefun} data={userData} mainmyloading={loading}/>
}


      

      



    </div>
  )
}

export default Usermain