import React, { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import { memo } from 'react'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'


const LineChartsDash = () => {
    const UserData = useSelector((state) => state.userReducer)
    const TxnData = useSelector((state) => state.txnReducer)
    const WithData = useSelector((state) => state.witxnReducer)
    const clickData = useSelector((state) => state.clickReducer)

    
    const myDta = []
    const txnDta = []
    const WithDta = []
    const ActivDta = []

    const ClkDta = []



 



    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //        const onPageLoad = ()=>{
           
    //             for(let i=1;i<13;i++){
    //                 const MonthLen = UserData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
    //                 const MonthTxn = TxnData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
    //                 const WithMonthTxn = WithData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
    //                 const ActiveMonthuser = UserData.filter((val)=>dedectMonth(val.lastlogin)==`${i}${new Date().getFullYear()}`)
             
    //                 const ClickMonthuser = clickData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
    //                 ActivDta.push(ActiveMonthuser.length)
    //                  myDta.push(MonthLen.length)
    //                  txnDta.push(MonthTxn.length)
    //                  WithDta.push(WithMonthTxn.length)
    //                  ClkDta.push(ClickMonthuser.length)
        
    //                  console.log('dd')
             
    //              }
            
    //        }

    //         // if (document.readyState === 'complete') {
    //         //     onPageLoad();
    //         //   } else {
    //         //     window.addEventListener('load', onPageLoad);
    //         //     // Remove the event listener when component unmounts
    //         //     return () => window.removeEventListener('load', onPageLoad);
    //         //   }
    //         onPageLoad()
    //     }, 1000);
    //     return () => clearTimeout(timer);
    //   }, []);


    






    const dedectMonth = (datee)=>{
        var mysevendays = new Date(datee);
        return `${mysevendays.getMonth()+1}${mysevendays.getFullYear()}`
    }




// console.log(window)

    



    


    const callforFun = ()=>{
       
        for(let i=1;i<13;i++){
            const MonthLen = UserData?.TotalUsers?.docs.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
            const MonthTxn = TxnData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
            const WithMonthTxn = WithData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
            const ActiveMonthuser = UserData?.TotalUsers?.docs.filter((val)=>dedectMonth(val.lastlogin)==`${i}${new Date().getFullYear()}`)
     
            const ClickMonthuser = clickData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
     
     
     
            ActivDta.push(ActiveMonthuser?.length)
             myDta.push(MonthLen?.length)
             txnDta.push(MonthTxn?.length)
             WithDta.push(WithMonthTxn?.length)
             ClkDta.push(ClickMonthuser?.length)

            //  console.log('dd')
     
         }
      
    }

    
    callforFun()



    // console.log(myDta)
  
  

 
    







  return (
    <div>

        <Chart type='line' height={300} 
        series={[
            {name:'User',data:myDta},
            {name:'Transaction',data:txnDta},
            {name:'Withdrawal',data:WithDta},
            {name:'Active ',data:ActivDta,color:'#1e5c2a'},
            {name:'Clicks ',data:ClkDta,color:'#9c1c7c'},

            // {name:'Clicks ',data:[45,87,54,1,9,58,41,69],color:'#9c1c7c'},
        ]}
        options={{
            title:{text:`Analytics This Year ${new Date().getFullYear()}`},
            xaxis:{
                // title:{text:"Users in This Year"},
                categories:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            }
        }}
        >

        </Chart>

    </div>
  )
}

export default LineChartsDash