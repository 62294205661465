
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import {useDispatch,useSelector} from 'react-redux'
import { fethselectedcategory, fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethcategory } from '../../redux/actions/categoryaction'

const Addcategory = () => {

  const {id} = useParams()

  const dispatch = useDispatch()

  const state5 = useSelector((state)=>state.selectedReducer)

  const token = localStorage.getItem('token')

const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    name:'',
    icon:'',
    sortdes:'',
    isactive:'',
    
   
})

const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}




const callingAPI = ()=>{
  dispatch(fethselectedcategory(id))
}

const updateForm = async ()=>{
        
  const userData = await state5.filter((val)=>val.id==id)
  
  userData.map((val)=>setmydta(val))

  // console.log(userData)

 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}


const handlBtnClk = ()=>{
   

    id?gpeapi.put(`/updatecategory/${id}`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    :gpeapi.post('/addcategory',mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))

  // console.log(mydta)
  }









// console.log(state5[0])
const match = state5[0]?.id==id

useEffect(()=>{
  id && callingAPI()
  updateForm()
},[match,id])






  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={'Category Name'} placeholder={'Please Enter Category Name'} name='name' value={mydta.name} onChange={handleChnage}  />
       
        <Inputcom label={'Category Icon Url'} placeholder={'Please Enter Category icon URL'} name='icon' value={mydta.icon} onChange={handleChnage}/>
        {<div className='flex justify-center  '>
          <img className='bg-red-200 rounded-md' src={mydta.icon?mydta.icon:'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{width:300 , height:100,padding:3}}/>
          </div>}

        

        <Inputcom label={'Category Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage}/>
        
        

        <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/>

        

        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/categorys'} dispatchfun={fethcategory}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE CATEGORY':'ADD CATEGORY'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>

        


        



    </div>
  )
}

export default Addcategory