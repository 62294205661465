import React, { useState } from 'react'
import OfferdataTable from '../../Components/OfferdataTable'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spinner } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Btnwithicon from '../../Components/Btnwithicon';
import { useNavigate } from "react-router-dom";
import SendnotifiAll from '../../Components/SendnotifiAll';
import { fethOffers } from '../../redux/actions/offeraction';
import Mybtn from '../../Components/Mybtn';

const Mainoffer = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const offerData = useSelector((state) => state.offerReducer)

  const AddofferBtnClk = () => {
    navigate('/addoffer')

  }

  const [findid, setfindid] = useState('')
  
  const filldata = offerData.filter((x) => {
    return findid !== "" ? x.id.toString().includes(findid) || x.amount.toString().includes(findid) || x.name.toLowerCase().includes(findid) || x.name.includes(findid) : x
  })

  const [referesloading,setreferesloading] = useState(false)

  const reloadAction =async ()=>{
    setreferesloading(true)
    await dispatch(fethOffers())
    setreferesloading(false)

  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

      <Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />

        <Btnwithicon color={'#2a2185'} title={'ADD OFFER'} icon={faCirclePlus} onClick={AddofferBtnClk} />


      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

      </div>
      <OfferdataTable data={filldata} />



    </div>
  )
}

export default Mainoffer