import { Slider } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery';

const Offerchartcom = ({ data }) => {
    const offerData = useSelector((state) => state.offerReducer)


    const filterOff = offerData.filter((val) => val?.name == data?.name)[0]
    const matches = useMediaQuery('(max-width:500px)');
    // console.log(matches)


    return (


        <div style={{ display: 'flex', borderRadius: 8, border: '0.5px solid #0ecca6', justifyContent: 'center', margin: matches?2:5, padding: matches?2:5 }}>
            <div style={{ marginRight: 5 }}>
                <img src={filterOff?.icon} height={matches?20:40} width={matches?20:40} style={{ borderRadius: 5 }} />
            </div>
            <div>
                <div style={{ marginRight: 5 }}>
                    <Slider
                        // defaultValue={200}
                        max={data?.count >= 100 ? 1000 : data?.count >= 1000? 100000 : 100}
                        value={data?.count}
                        style={{ width: matches?70:110, marginLeft: 10 }}
                        valueLabelDisplay="auto"
                        


                    />
                    <p style={{ fontSize: 12,color:'green' }}>{data?.count}/{data?.count >= 100 ? 1000 : data?.count >= 1000? 100000 : 100}</p>

                </div>
                <p style={{ fontSize:matches?8: 11 }}>{filterOff?.partner?.length>10?`${filterOff?.partner?.slice(0,10)}..`:filterOff?.partner}</p>
                {/* <p style={{ fontSize: 14 }}>number ss</p> */}
                <p style={{ fontSize: 11 }}>Status: <span style={{ color: data?.isactive ? 'green' : 'red' }}>{data?.isactive ? 'True' : 'False'}</span></p>

            </div>

        </div>


    )
}

export default Offerchartcom