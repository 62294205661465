import gpeapi from "../../api/gpeapi";
import { SET_SCRATCHCARD } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')

export const fetchscratchcard = () => {
    return async function (dispatch) {



        const res = await gpeapi.get('/getscratchcard', {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        })


      

       const fillterdtoNew = res.data?.mydta



        dispatch(setSCRATCHCARD(fillterdtoNew))

    }
}



export const setSCRATCHCARD = (data = null) => {
    if (data) {
        return {
            type: SET_SCRATCHCARD,
            payload: data,
        };
    }

    return {
        type: SET_SCRATCHCARD,
        payload: [],
    };
};

