import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Btnwithicon from '../../../Components/Btnwithicon';
import Iplteamtable from '../../../Components/Tablesdata/Iplteamtable';


const MainiplTeam = () => {

  const navigate = useNavigate()

  const offerData = useSelector((state) => state.iplteamsReducer)

  const AddofferBtnClk = () => {
    navigate('/addiplteams')

  }

  const [findid, setfindid] = useState('')
  
  const filldata = offerData.filter((x) => {
    return findid !== "" ? x.id.toString().includes(findid) || x.amount.toString().includes(findid) || x.name.toLowerCase().includes(findid) || x.name.includes(findid) : x
  })

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        <Btnwithicon color={'#2a2185'} title={'ADD OFFER'} icon={faCirclePlus} onClick={AddofferBtnClk} />


      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

      </div>
      {/* <OfferdataTable data={filldata} /> */}

      <Iplteamtable data={filldata}/>



    </div>
  )
}

export default MainiplTeam

