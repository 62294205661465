import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'



import { fethselectediplmatches, fethselectediplteams } from '../../../redux/actions/selectedaction'
import Inputcom from '../../../Components/Inputcom'
import Popupnoti from '../../../Components/Popupnoti'
import gpeapi from '../../../api/gpeapi'
import Btnwithicon from '../../../Components/Btnwithicon'
import Selectcom from '../../../Components/Selectcom'
import { fethiplteams } from '../../../redux/actions/iplteams'
import Textareacom from '../../../Components/Textareacom'
import iplteamsReducer from '../../../redux/reducerse/iplteamreducer'
import Calendarmy from '../../../Components/Shortcom/Calendarmy'
import { fethiplmatches } from '../../../redux/actions/iplmatches'

const Addiplmatch = () => {

    const { id } = useParams()

    const dispatch = useDispatch()



    const state5 = useSelector((state) => state.selectedReducer)

    const iplTeams = useSelector((state) => state.iplteamsReducer)
    const [startDate, setStartDate] = useState(null)






    const token = localStorage.getItem('token')
    const [sendnoti, setsendnoti] = useState('')



    const [notidata, setnotidata] = useState({
        msg: '',
        success: '',
        show: false
    })

    const [mydta, setmydta] = useState({
        name: '',
        sortdes: '',
        fulldes: '',
        isactive: '',
        matchno: '',
        teama: '',
        teamb: '',
        teamaicon: '',
        teambicon: '',
        matchdate: '',
        matchtime: '',
        winningteam: '',
        winningcomment: '',



    })

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        setmydta({ ...mydta, [name]: value })
    }



    
  

    const callingAPI = () => {
        dispatch(fethselectediplmatches(id))
    }

    const updateForm = async () => {

        const userData = await state5.filter((val) => val.id == id)

       await userData.map((val) => setmydta(val))

        // var dateObj = new Date(mydta?.matchdate);
      
    // subtract one day from current time                        
    // dateObj?.setDate(dateObj?.getDate() - 1);

    // console.log(dateObj)

        // setStartDate(dateObj)


        // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})

    }

    // console.log(allfcmtokens)






    const handlBtnClk = () => {




        id ? gpeapi.put(`/updateiplmatches/${id}`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))
            : gpeapi.post('/createiplmatches', mydta, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }).then(async (res) => {
                setnotidata({ msg: res.data.msg, show: true, success: res.data.success })


            }).catch((e) => console.log(e, 'errr'))


        // console.log(mydta)
    }









    // console.log(state5[0])
    const match = state5[0]?.id == id

    useEffect(() => {
        id && callingAPI()
        updateForm()
    }, [match, id])




    const handelteama = async (e) => {
        const filterdata = await iplTeams?.filter((val) => val.name == e?.target?.value)[0]
        setmydta({...mydta, teama: filterdata?.name, teamaicon: filterdata?.icon })
    }

    const handelteamb = async (e) => {
        const filterdata = await iplTeams?.filter((val) => val.name == e?.target?.value)[0]
        setmydta({...mydta,  teamb: filterdata?.name, teambicon: filterdata?.icon })
    }

    const handelteamaaa = async (e) => {
        const filterdata = await iplTeams?.filter((val) => val.name == e?.target?.value)[0]
        setmydta({...mydta,  winningteam: filterdata?.name })
    }


    const handledateset = async (e) => {
        var date = new Date(e);
        date.setDate(date.getDate() + 1);
        setmydta({...mydta, matchdate:date.toISOString()})
       
    }

    // console.log(mydta.matchdate)



    return (
        <div style={{ width: '90%', marginTop: 30 }}>

            <Inputcom label={' Name'} placeholder={'Please Enter  Name'} name='name' value={mydta.name} onChange={handleChnage} />

            <div className='mb-6 mt-6' style={{ position: 'relative' }}>
                <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{'Team A Name '}</label>
                <select onChange={handelteama} value={mydta.teama} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected disabled hidden>Select here</option>
                    {iplTeams.map((val, i) => (
                        <option key={i} value={val.name}>{val.name}</option>

                    ))}
                </select>
            </div>

            <div className='mb-6 mt-6' style={{ position: 'relative' }}>
                <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{'Team B Name '}</label>
                <select onChange={handelteamb} value={mydta.teamb} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected disabled hidden>Select here</option>
                    {iplTeams.map((val, i) => (
                        <option key={i} value={val.name}>{val.name}</option>

                    ))}
                </select>
            </div>


            <div className='mb-6 mt-6' style={{ position: 'relative' }}>
                <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{'Match Date'}</label>

                <Calendarmy value={startDate} onChange={(e) => {
                    setStartDate(e)
                    handledateset(e)
                }} />

            </div>

            <div className='mb-6 mt-6' style={{ position: 'relative' }}>
                <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{'Match Time'}</label>

               <input type='time' value={mydta.matchtime} onChange={(e)=>setmydta({...mydta, matchtime:e.target.value})}/>

            </div>



            <Inputcom label={' Match No.'} placeholder={'Please Enter  Match No.'} name='matchno' value={mydta.matchno} onChange={handleChnage} />







            <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage} />

            <Textareacom label='Full Des' placeholder='Please Enter Full Des' name='fulldes' value={mydta.fulldes} onChange={handleChnage} />


           








            {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}

            {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

            <Selectcom label={'is Active'} data={['true', 'false']} name='isactive' onChange={handleChnage} value={mydta.isactive} />


            <div className='mb-6 mt-6' style={{ position: 'relative' }}>
                <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{'Winning Team '}</label>
                <select onChange={handelteamaaa} value={mydta.winningteam} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected disabled hidden>Select here</option>
                    {iplTeams.map((val, i) => (
                        <option key={i} value={val.name}>{val.name}</option>

                    ))}
                </select>
            </div>

            <Textareacom label='winningcomment' placeholder='winningcomment' name='winningcomment' value={mydta.winningcomment} onChange={handleChnage} />





            <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/iplmatches'} dispatchfun={fethiplmatches} />

            <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id ? 'UPDATE OFFER' : 'ADD OFFER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>











        </div>
    )
}

export default Addiplmatch
