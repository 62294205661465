
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faIndianRupeeSign,faXmark,faEye,faEdit,faTrash,faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import gpeapi from '../../api/gpeapi';
import Deletenoti from '../Deletenoti';
import Pagination from '../Pagination';

const Categorytable = ({options,data}) => {
  const token = localStorage.getItem('token')

  const navigate = useNavigate()

  const handleditclk = (id)=>{
    navigate(`/updatecategory/${id}`)
  }

  const handledeletclk = (id)=>{
    const onMyclk = ()=>{
      gpeapi.delete(`/deletecategory/${id}`,{headers:{
        "Authorization":`Beaer ${token}`}}
      ).then((res)=>res.data).catch((e)=>console.log(e))
    }
    Deletenoti({text:`${id} CategoryID will Delete in your System`,onClick:onMyclk})
  }

  const [currentPage, setCureentPage] = useState(1)
  const [userPerPage, setuserPerPage] = useState(15)
  const [myfilter, setmyfilter] = useState([])


  const indexLastUser = currentPage * userPerPage;
  const indexFirstUser = indexLastUser - userPerPage;
  const currentuser = data.slice(indexFirstUser, indexLastUser)


  useEffect(() => {
    setmyfilter(data)
  }, [data])

  const paginate = (number) => {
    setCureentPage(number)
  }

    
  return (
    <div className='container'>
      <br />

      <div className='d-flex justify-content-between'>
        


        {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




      </div>
      <br />



      <TableContainer component={Paper}>
        <Table  sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Icon</strong></TableCell>
              <TableCell ><strong>Name</strong></TableCell>
              <TableCell ><strong>Sort Des</strong></TableCell>
              <TableCell ><strong>isActive</strong></TableCell>
              <TableCell align='right'><strong>Change</strong></TableCell>
              
              
            </TableRow>
          </TableHead>
          <TableBody>
            {currentuser && currentuser.map((row,i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell ><img src={row.icon} style={{width:50,height:50}}/></TableCell>
                <TableCell >{row.name}</TableCell>
                <TableCell >{row.sortdes}</TableCell>
                <TableCell >{row.isactive?<FontAwesomeIcon icon={faCircleCheck} color={'#2a2185'} />:<FontAwesomeIcon icon={faXmark} color={'red'}/>}</TableCell>

                <TableCell  style={{display:'flex',justifyContent:'space-evenly',border:0}}>
                    <FontAwesomeIcon className='usertablicon'  color='yellowgreen' icon={faEye}  />
                    <FontAwesomeIcon className='usertablicon' color='#2a2185' onClick={()=>handleditclk(row.id)} icon={faEdit} />
                    <FontAwesomeIcon className='usertablicon' color='red' onClick={()=>handledeletclk(row.id)} icon={faTrash} />
                   
                    </TableCell>
                
                



              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination perPage={userPerPage} totalData={data.length} paginate={paginate} currentPage={currentPage}/>

    </div>
  )
}

export default Categorytable