import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {useDispatch,useSelector} from 'react-redux'










import { fethselectedscratchcard } from '../../redux/actions/selectedaction'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Selectcom from '../../Components/Selectcom'
import { fetchscratchcard } from '../../redux/actions/scratchcardaction'

const Addscratchcard = () => {

  const {id} = useParams()

  const dispatch = useDispatch()



  const state5 = useSelector((state)=>state.selectedReducer)
 
  
  

  const token = localStorage.getItem('token')
const [sendnoti,setsendnoti] = useState('')



const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    txnname:'',
    userid:'',
    sortdes:'',
    isactive:'',
    amount:'',
    isclaimed:'',
    isexpired:'',

    
})

const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}





const callingAPI = ()=>{
  dispatch(fethselectedscratchcard(id))
}

const updateForm = async ()=>{
        
  const userData = await state5.filter((val)=>val.id==id)
  
  userData.map((val)=>setmydta(val))



 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}

// console.log(allfcmtokens)






const handlBtnClk = ()=>{
  
  
   

    id?gpeapi.put(`/updatescratchcard/${id}`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    :gpeapi.post('/createscratchcard',mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then(async (res)=>{
    setnotidata({msg:res.data.msg,show:true,success:res.data.success})
    
    
  }).catch((e)=>console.log(e,'errr'))


  // console.log(mydta)
  }









// console.log(state5[0])
const match = state5[0]?.id==id

useEffect(()=>{
  id && callingAPI()
  updateForm()
},[match,id])





  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={'Scratch card Name'} placeholder={'Please Enter Scratch card '} name='txnname' value={mydta.txnname} onChange={handleChnage}  />


        <Inputcom label={'Userid'} placeholder={'Please Enter Userid'} name='userid' value={mydta.userid} onChange={handleChnage}/>
        
        <Inputcom label={'Amount'} placeholder={'Please Enter Amount '} name='amount' value={mydta.amount} onChange={handleChnage}/>
        

        

        <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage}/>
      
        {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}
        
        {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

        <Selectcom label={'is claimed'} data={['true','false']} name='isclaimed' onChange={handleChnage} value={mydta.isclaimed}/>

        <Selectcom label={'is Expired'} data={['true','false']} name='isexpired' onChange={handleChnage} value={mydta.isexpired}/>

        <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/>

     

        

        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/scratchcard'} dispatchfun={fetchscratchcard}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE OFFER':'ADD OFFER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>


       

        


        



    </div>
  )
}

export default Addscratchcard

