import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'



import { fethselectediplpredict, fethselectediplteams } from '../../../redux/actions/selectedaction'
import Inputcom from '../../../Components/Inputcom'
import Popupnoti from '../../../Components/Popupnoti'
import gpeapi from '../../../api/gpeapi'
import Btnwithicon from '../../../Components/Btnwithicon'
import Selectcom from '../../../Components/Selectcom'
import { fethiplteams } from '../../../redux/actions/iplteams'
import Mybtn from '../../../Components/Mybtn'
import Viewparticipants from './Viewparticipants'
import { fethiplpredicts } from '../../../redux/actions/iplpredictaction'

const Addiplpredict = () => {

  const { id } = useParams()

  const dispatch = useDispatch()

const [showparti,setshowparti] = useState(false)

const scratchvcarddata = useSelector((state) => state.scratchReducer).filter((val)=>val.from==id)




  const state5 = useSelector((state) => state.selectedReducer)

  const iplTeams = useSelector((state) => state.iplteamsReducer)
  

  const [teamApredicts, setteAmapredicts] = useState(0)
  const [teamBpredicts, setteBmapredicts] = useState(0)



  const token = localStorage.getItem('token')
  const [sendnoti, setsendnoti] = useState('')



  const [notidata, setnotidata] = useState({
    msg: '',
    success: '',
    show: false
  })

  const [mydta, setmydta] = useState({
    matchname: '',
    matchid: '',
    matchnumber: '',
    isactive: '',
    sortdes: '',
    winningteam: '',
    min_scratch_amt: '',
    max_scratch_amt: '',
    totalscratchcard: '',
    isresultdeclare: '',
    participants: '',
    iswinnerdeclare:'',


  })

  // console.log(mydta)

  

  const handleChnage = (e) => {
    const name = e.target.name
    const value = e.target.value

    setmydta({ ...mydta, [name]: value })
  }





  const callingAPI = () => {
    dispatch(fethselectediplpredict(id))
  }

  const updateForm = async () => {

    const userData = await state5.filter((val) => val.id == id)

    userData.map((val) => setmydta(val))




    // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})

  }

  // console.log(allfcmtokens)


  const iplmatche = useSelector((state) => state.iplmatchesReducer).filter((val) => val?.id == id)[0]



  const handlBtnClk = () => {




    id ? gpeapi.put(`/updateiplpredict/${id}`, mydta, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))
      : gpeapi.post('/createiplpredict', mydta, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then(async (res) => {
        setnotidata({ msg: res.data.msg, show: true, success: res.data.success })


      }).catch((e) => console.log(e, 'errr'))


    // console.log(mydta)
  }



  // console.log(state5[0])
  const match = state5[0]?.id == id

  useEffect(() => {
    id && callingAPI()
    updateForm()
  }, [match, id])


  // console.log(mydta.isresultdeclare)



  const handelteamb = async (e) => {
    const filterdata = await iplTeams?.filter((val) => val.name == e?.target?.value)[0]
    setmydta({ ...mydta, winningteam: filterdata?.name })
  }


  // console.log(mydta.participants)
  // console.log(iplmatche)

  const findTeamaPredicts =async ()=>{
    const dataaa =  mydta?.participants?.filter((val)=>val.predictteam==iplmatche?.teama)
    // console.log(dataaa)
    return dataaa?.length
  }

  const findTeamBPredicts =async ()=>{
    const dataaa =  mydta?.participants?.filter((val)=>val.predictteam==iplmatche?.teamb)
    // console.log(dataaa)
    return dataaa?.length
  }

  


  useEffect(() => {
    callforTeamPredictt()
  }, [mydta])

  const callforTeamPredictt =async ()=>{
    const TeamA =await findTeamaPredicts()
    const TeamB =await findTeamBPredicts()
    setteAmapredicts(TeamA)
    setteBmapredicts(TeamB)
  }

  // const updatepredicted = async () => {
  //   const fill =await mydta?.participants?.map((val, i,ele) => {
  //     if (val.predictteam == iplmatche?.teama) {
        
  //       console.log(ele)
  //     } else if (val.predictteam == iplmatche?.teamb) {
  //       setteBmapredicts(teamBpredicts + 1)
  //     }
  //   })

  // }



  return (
    <div style={{ width: '90%', marginTop: 30 }}>
      {id && <Viewparticipants data={mydta?.participants} show={showparti} updatestate={setshowparti} matchdata={state5}/>}

      

      <div style={{display:'flex',justifyContent:'space-around'}}>
      <div style={{display:'inline-flex',justifyContent:'center',backgroundColor:'#343c49',color:'white',padding:10,borderRadius:10}}>
        <div style={{margin:'0px 10px'}}>
          <p>{iplmatche?.teama}</p>
          <p>{teamApredicts}</p>
        </div>

        {/* <hr style={{fl}}/> */}

        <div style={{margin:'0px 10px'}}>
          <p>{iplmatche?.teamb}</p>
          <p>{teamBpredicts}</p>
        </div>

      </div>

      <div style={{display:'inline-flex',justifyContent:'center',backgroundColor:'#343c49',color:'white',padding:10,borderRadius:10}}>
        <div style={{margin:'0px 10px'}}>
          <p>User Total Winners - {scratchvcarddata?.length}</p>
          <p>User AMT Winners - {scratchvcarddata?.filter((val)=>val.amount!==0)?.length}</p>
          <p>Total win Amount  -{scratchvcarddata?.reduce((prev,curr)=>prev+curr?.amount,0)}₹</p>
          <p>Scratched User  -{scratchvcarddata?.filter((val)=> val.isclaimed==true)?.length}</p>
        </div>

        {/* <hr style={{fl}}/> */}

       

      </div>

      

      <Mybtn title={'View Participants'} onClick={()=>setshowparti(true)}/>
      </div>


      <Inputcom label={'Match Name'} placeholder={'Please Enter Match Name'} name='matchname' value={mydta.matchname} onChange={handleChnage} />

      <Inputcom label={'matchnumber'} placeholder={'Please Enter matchnumber'} name='matchnumber' value={mydta.matchnumber} onChange={handleChnage} />

      <Inputcom label={'matchid'} placeholder={'Please Enter matchid'} name='matchid' value={mydta.matchid} onChange={handleChnage} />

      <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage} />

   

      <Selectcom label={'is Active'} data={['true', 'false']} name='isactive' onChange={handleChnage} value={mydta.isactive} />


      <div className='mb-6 mt-6' style={{ position: 'relative' }}>
        <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{'Winner Team Name'}</label>
        <select onChange={handelteamb} id="countries" value={mydta.winningteam} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="" selected disabled hidden>Select here</option>
          {iplTeams.map((val, i) => (
            <option key={i} value={val.name}>{val.name}</option>

          ))}
        </select>
      </div>

      <Selectcom label={'is Winner Declear'} data={['true', 'false']} name='iswinnerdeclare' onChange={handleChnage} value={mydta.iswinnerdeclare} />


      <Selectcom label={'is resultdeclare'} data={['true', 'false']} name='isresultdeclare' onChange={handleChnage} value={mydta.isresultdeclare} />

      {
        mydta.isresultdeclare && <>
          <Inputcom label={'Min SCratch AMt'} placeholder={'Min SCratch AMt'} name='min_scratch_amt' value={mydta.min_scratch_amt} onChange={handleChnage} />

          <Inputcom label={'Max SCratch AMt'} placeholder={'Max SCratch AMt'} name='max_scratch_amt' value={mydta.max_scratch_amt} onChange={handleChnage} />

          <Inputcom label={'Numbers of Scratch Card'} placeholder={'Numbers of scratch card'} name='totalscratchcard' value={mydta.totalscratchcard} onChange={handleChnage} />

        </>
      }








      <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/iplpredicts'} dispatchfun={fethiplpredicts} />

      <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id ? 'UPDATE OFFER' : 'ADD OFFER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>











    </div>
  )
}

export default Addiplpredict
