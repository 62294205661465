import { faCirclePlus, faRemove } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import { useDispatch, useSelector } from 'react-redux'
import { fethselectedbanner, fethselectedbasic, fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethbasic } from '../../redux/actions/basicaction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Mybtn from '../../Components/Mybtn'

const Addbasic = () => {

  const [formValues, setFormValues] = useState([
    {
      name: "",
      inputtext: "",
      mintxn: '',
      type: '',
      icon: '',
      note: '',
      isactive:Boolean

    }
  ])


  const { id } = useParams()

  const dispatch = useDispatch()

  const state5 = useSelector((state) => state.selectedReducer)
  const state6 = useSelector((state) => state.basicReducer)

  const token = localStorage.getItem('token')

  const [notidata, setnotidata] = useState({
    msg: '',
    success: '',
    show: false
  })


  const [mydta, setmydta] = useState({
    appname: '',
    icon: '',
    signupamt: '',
    referamt: '',
    withdrawamt: '',
    appdes: '',
    appurl: '',
    version: '',
    telegramurl: '',
    tandc: '',
    ipltandc: '',
    howtoplayipl: '',
    paymentmode: '',
    dailycheckin:''


  })

  const handleChnage = (e) => {
    const name = e.target.name
    const value = e.target.value

    setmydta({ ...mydta, [name]: value })
  }

  const handleChnagenew = (e, i) => {
    const value = e.target.value;
    const name = e.target.name;
    const list = [...formValues]
    list[i][name] = value
    setFormValues(list)



  }

  const handleplushicon = () => {
    setFormValues([...formValues, {
      name: "",
      inputtext: "",
      mintxn: '',
      type: '',
      icon: '',
      note: '',
      isactive:Boolean

    }])
  }

  const handleremoveicon = (i) => {
    const list = [...formValues];
    list.splice(i, 1)
    setFormValues(list)


  }

  const cashratedataadd = () => {
    setmydta({ ...mydta, paymentmode: formValues })
    alert('added done')
  }






  const callingAPI = () => {
    dispatch(fethselectedbasic())
  }

  const updateForm = async () => {

    const userData = await state6.filter((val) => val.id == 1)



    if (userData[0]?.paymentmode.length == 0) {
      setFormValues([{
        name: "",
        inputtext: "",
        mintxn: '',
        type: '',
        icon: '',
        note: '',
        isactive:Boolean

      }])
    } else {

      userData.map((val) => setFormValues(val.paymentmode))
    }

    userData.map((val) => setmydta(val))






    // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})

  }


  const handlBtnClk = () => {


    gpeapi.put(`/updatebasic`, mydta, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))

    // console.log(mydta)
  }









  // console.log(state5[0])
  const match = state6[0]?.id == 1

  useEffect(() => {
    id && callingAPI()
    updateForm()
  }, [match, id])






  return (
    <div style={{ width: '90%', marginTop: 30 }}>

      <Inputcom label={'App Name'} placeholder={'Please Enter App Name'} name='appname' value={mydta.appname} onChange={handleChnage} />

      <Inputcom label={'App Url'} placeholder={'Please Enter App URL'} name='appurl' value={mydta.appurl} onChange={handleChnage} />

      <Inputcom label={'App IMG Url'} placeholder={'Please Enter App IMG URL'} name='icon' value={mydta.icon} onChange={handleChnage} />

      {<div className='flex justify-center  '>
        <img className='bg-red-200 rounded-md' src={mydta.icon ? mydta.icon : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{ width: 110, height: 100, padding: 3 }} />
      </div>}


      <Inputcom label={'App Telegram Url'} placeholder={'Please Enter App Telegram URL'} name='telegramurl' value={mydta.telegramurl} onChange={handleChnage} />

      <Inputcom label={'App Description'} placeholder={'Please Enter App Description'} name='appdes' value={mydta.appdes} onChange={handleChnage} />



      <Inputcom label={'Signup AMT'} placeholder={'Please Enter Signup AMT'} name='signupamt' value={mydta.signupamt} onChange={handleChnage} />

      <Inputcom label={'Refer AMT'} placeholder={'Please Enter Refer AMT'} name='referamt' value={mydta.referamt} onChange={handleChnage} />

      <Inputcom label={'Withdrwa AMT'} placeholder={'Please Enter Withdrwa AMT'} name='withdrawamt' value={mydta.withdrawamt} onChange={handleChnage} />

      <Inputcom label={'App Version'} placeholder={'Please Enter App Version'} name='version' value={mydta.version} onChange={handleChnage} />


      <Textareacom label='App T&C' placeholder='Please Enter App T&Cs' name='tandc' value={mydta.tandc} onChange={handleChnage} />
      <Textareacom label='IPL T&C' placeholder='Please Enter IPL T&Cs' name='ipltandc' value={mydta.ipltandc} onChange={handleChnage} />
      <Textareacom label='IPL How to Play' placeholder='Please Enter IPL How to play' name='howtoplayipl' value={mydta.howtoplayipl} onChange={handleChnage} />

      <Selectcom label={'Daily checkin AD'} data={['true','false']} name='dailycheckin' onChange={(e) => handleChnage(e)} value={mydta.dailycheckin}/>


<div>

      <div style={{ backgroundColor: 'white', borderRadius: 10, margin: 10, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {
          formValues && formValues.map((val, i) => (
            <div style={{ backgroundColor: 'white', borderRadius: 5, margin: 10, padding: 15, border: '1px solid black',display:'block',justifyContent:'center',alignItems:'center' }}>

              <img src={val?.icon} height={100} width={100} style={{margin:'auto'}}/>
             
              <Inputcom label={'Wallet Name'} placeholder={'Please Enter Name'} name='name' value={val.name} onChange={(e) => handleChnagenew(e, i)} />

              <Inputcom label={'ICON'} placeholder={'Please Enter ICON'} name='icon' value={val?.icon} onChange={(e) => handleChnagenew(e, i)} />

              <Inputcom label={'Type'} placeholder={'Please Enter Type '} name='type' value={val?.type} onChange={(e) => handleChnagenew(e, i)} />
              <Inputcom label={'Min TXN'} placeholder={'Please Enter Min TXn'} name='mintxn' value={val?.mintxn} onChange={(e) => handleChnagenew(e, i)} />
              <Inputcom label={'inputtext'} placeholder={'Please Enter inputtext'} name='inputtext' value={val?.inputtext} onChange={(e) => handleChnagenew(e, i)} />
              <Inputcom label={'note'} placeholder={'Please Enter note'} name='note' value={val?.note} onChange={(e) => handleChnagenew(e, i)} />

              <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={(e) => handleChnagenew(e, i)} value={val.isactive}/>


              {
                formValues.length !== 1 && <FontAwesomeIcon onClick={() => handleremoveicon(i)} style={{ marginLeft: 6, cursor: 'pointer', fontSize: 30 }} icon={faRemove} />
              }
              {
                formValues.length - 1 == i && <FontAwesomeIcon onClick={handleplushicon} style={{ marginLeft: 6, cursor: 'pointer', fontSize: 30 }} icon={faCirclePlus} />
              }

            </div>
          ))
        }

<Mybtn title={'ADD PAYMENT MODE'} onClick={cashratedataadd}/>


      </div>

      
</div>








      {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}

      {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

      {/* <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/> */}



      <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/basic'} dispatchfun={fethbasic} />

      <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlBtnClk} color={'white'} title={'UPDATE BASIC'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>








    </div>
  )
}

export default Addbasic


