import axios from "axios"
import gpeapi from "../api/gpeapi"


const NotificationTest = async (msg) => {


    const token = localStorage.getItem('token')

    const msgmy = {
        msg: {
            "title": msg.title,
            "body": msg.subtitle,
            "image": msg.img,
        }
    }

    await gpeapi.post(`/sendtestnotifi`,msgmy,
        {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res)=>console.log('noti sent')).catch((e)=>console.log('not sent'))

    // const array = msg.tokens;
    // const new_array = [];

    // const config = {
    //     headers: {
    //         "Authorization": `key=AAAAcWkqhpk:APA91bE5zjWHw-puATQOfLA7n95Me1sWfxt0AhY5as2uSZnrlQ5HRJC1KeElqPviIOdhsDw1HBfMZnH_Gi27-Gwfn3C7iiyRDbgXk6wQ3cW7MaLisolui8Y5koKtCLiYKqYqm4jZ3Nyv`,
    //         "Content-type": "Application/json",
    //     },
    // }




    // const chunksize = 490;
    // while (array.length) {
    //     const chunk = array.splice(0, chunksize);
    //     new_array.push(chunk);
    // }
    // for (let i = 0; i < new_array.length; i++) {

    //     const data = {
    //         "registration_ids":new_array[i],
    //         "notification": {
    //             "title": msg.title,
    //             "body": msg.subtitle,
    //             "image": msg.img,
    //             "sound": "ring_bell",
    //             "icon": "ic_notification",
    //             "android_channel_id": "sound_channel"
    //         }
    //     }

    //    axios.post('https://fcm.googleapis.com/fcm/send', data, config).then((res) => {
    //     console.log('Notification Sent Done',res)
    //     // alert(`Notification Sent to ${res?.data?.success}`)
    //     return res
    // })

    // }




}

export default NotificationTest