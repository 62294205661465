import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck, faClock, faBan } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import gpeapi from '../api/gpeapi';
import Deletenoti from './Deletenoti';
import Popupnoti from './Popupnoti';
import { fetchtxn } from '../redux/actions/txnaction';
import { fetchwitxn } from '../redux/actions/witxnaction';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from './Pagination';
import Selectcom from './Selectcom';
import ReactLoading from "react-loading";
import { Spinner } from 'react-rainbow-components';
import Paginationnew from './Paginationnew';
import { fetchpendingtxn } from '../redux/actions/txnpendingaction';

const Txndatatablecard = ({data,row,index,i}) => {
  const token = localStorage.getItem('token')

  const [loading, setloading] = useState({
    mid:'',
    load:false
  })

  const dispatch = useDispatch()
  // console.log(window.location.pathname==='/pendingtxnhistory')

  const [notidata, setnotidata] = useState({
    msg: '',
    success: '',
    show: ''
  })

  const txnalldata = useSelector((state) => state.txnReducer)
  const useralldata = useSelector((state) => state.userReducer)

  const [totalOfferdone , settotalOfferdone] = useState(0)
  const [totalOfferdoneloading,settotalOfferdoneloading] = useState(false)
  const [checkuidoffer , setcheckuidoffer] = useState(0)
  const [checkuidofferloading,setcheckuidofferloading] = useState(false)




  

  const handlestatuchnage = async (e, id, userid, amt, types, txname) => {
    const data = {
      txnname: txname,
      userid: userid,
      amount: amt,
      type: types,
      status: e
    }
    setloading({mid:id,load:true})
    // console.log(data)
    await gpeapi.put(`/updatetxnhistory/${id}`, data, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then(async(res) => {
      await dispatch(fetchtxn(0,15,'',''))
       dispatch(fetchwitxn(0,15))
       dispatch(fetchpendingtxn(0,15))
    }).catch((e) => alert('some error'))
    setloading({mid:id,load:false})

  }

  const navigate = useNavigate()

  const convertDate = (date) => {
    const mydta = new Date(date);
    const year = mydta.getFullYear();
    const month = mydta.getMonth();
    const day = mydta.getDate()
    const gday = day < 10 ? `0${day}` : day

    return `${gday}-${month + 1}-${year}`
  }


  const getpmamvia = (date) => {
    const mydate = new Date(date)
    var hours = mydate.getHours();
    var minutes = mydate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime
  }


  const handleEdit = (id) => {
    navigate(`/updatetxn/${id}`)
    // navigate(0)
  }



  const handledeletclk = (id) => {
    const onMyclk = () => {
      gpeapi.delete(`/deletetxn/${id}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }
      ).then((res) => res.data).catch((e) => console.log(e))
    }
    Deletenoti({ text: `${id} TXNID will Delete in your System`, onClick: onMyclk })
  }




  const handleviewclick = (id)=>{
    navigate(`/viewuser/${id}`)
  }


  const checkmobile = (userid)=>{
    const fildta = useralldata?.TotalUsers?.docs.filter((val)=>val?.id==userid)[0]
    return fildta?.mobile
  }


  useEffect(()=>{
    callfirsttimeApi()
    callforcheckuidnew()
  },[])

  const callfirsttimeApi =async ()=>{
    settotalOfferdoneloading(true)
    const res = await gpeapi.get(`/getusertxndataviapagi?page=${0}&limit=${100}&userid=${row.userid}`,{headers:{
        "Authorization":`Beaer ${token}`
    }})
    const fildata = res.data?.TotalUsers?.docs?.filter((val) =>  val.txnname == row.txnname)
    settotalOfferdone(fildata?.length)
    settotalOfferdoneloading(false)

  }

  const callforcheckuidnew =async ()=>{
    setcheckuidofferloading(true)
    const res = await gpeapi.get(`/gettxnviapagi?page=${0}&limit=${100}&search=${row.deviceuid}`,{headers:{
        "Authorization":`Beaer ${token}`
    }})
    const fildata = res.data?.TotalUsers?.docs?.filter((val) => val?.deviceuid && val.deviceuid == row.deviceuid && val.txnname==row.txnname)
    setcheckuidoffer(fildata?.length)
    setcheckuidofferloading(false)


  }

 







  return (
  
          


                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className='ontablehover'
                  >

                    {index ? <TableCell >{data?.TotalUsers?.docs?.length - i}</TableCell> : null}
                    {/* <TableCell >
                <input type={'checkbox'} onChange={(e)=>console.log(e.target.value)}/>

                </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell >{row.userid}</TableCell>

                   


                    <TableCell >{row.txnname}   <span style={{ backgroundColor: '#484e54', padding: 2, color: 'white', fontSize: 15, borderRadius: 3 }}>{ totalOfferdoneloading?<ReactLoading height={5} width={15} color='red' type='spin' /> : totalOfferdone}</span> <p>{row?.from}</p> </TableCell>

                    {!index && <TableCell style={{ display: 'flex' }}>{row?.deviceuid}   <span style={{ backgroundColor: '#484e54', padding: 2, color: 'white', fontSize: 15, borderRadius: 3 }}>{ checkuidofferloading? <ReactLoading height={5} width={15} color='red' type='spin' />: checkuidoffer}</span> </TableCell>}

                    <TableCell >{row.txnid}</TableCell>

                    <TableCell >{row.type}</TableCell>
                    {index ? <TableCell >{row.paymode }</TableCell> : null}
                    {index ? <TableCell >{ row.paymode=='UPI'? row.upiid:row.mobile ? row.mobile : checkmobile(row.userid)}</TableCell> : null}
                    {/* {index ? <TableCell >{row.upiid }</TableCell> : null} */}

                    <TableCell style={{ fontWeight: 'bold', color: '#2a2185' }}>{row.amount}<FontAwesomeIcon icon={faIndianRupeeSign} fontSize={11} /></TableCell>

                    <TableCell>

                      <select disabled={row.status == 'Successful'} value={row.status} onChange={(e) => handlestatuchnage(e.target.value, row.txnid, row.userid, row.amount, row.type, row.txnname)}>
                        <option value={'Successful'}>Successful</option>
                        <option value={'Pending'}>Pending</option>
                        <option value={'Rejected'}>Rejected</option>
                      </select>
                    </TableCell>



                    <TableCell >{row.status == 'Successful' ? <FontAwesomeIcon icon={faCircleCheck} color={'	#42ba96'} /> : row.status == "Pending" ? <FontAwesomeIcon icon={faClock} color={'#bfb60d'} /> : <FontAwesomeIcon icon={faBan} color={'red'} />}  {loading.load && loading.mid == row.txnid && <ReactLoading height={5} width={15} color='red' type='spin' />}  </TableCell>

                    {/* <TableCell>
                 
                <select disabled={row.status=='Successful'} value={row.status} onChange={(e)=>handlestatuchnage(e.target.value,row.id)}>
                  <option value={'Successful'}>Successful</option>
                  <option value={'Pending'}>Pending</option>
                  <option value={'Rejected'}>Rejected</option>
                </select>
                </TableCell> */}

                    {/* <TableCell >{convertDate(row.createdAt)}</TableCell> */}

                    <TableCell >
                      <p>{convertDate(row.createdAt)}</p>
                      <p style={{ color: 'gray' }}>{getpmamvia(row.createdAt)}</p>

                    </TableCell>


                    <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', border: 0 }}>
                      <FontAwesomeIcon className='usertablicon' color='yellowgreen' icon={faEye} onClick={() => handleviewclick(row.userid)} />
                      <FontAwesomeIcon className='usertablicon' color='#2a2185' icon={faEdit} onClick={() => handleEdit(row.txnid)} />
                      <FontAwesomeIcon className='usertablicon' color='red' onClick={() => handledeletclk(row.id)} icon={faTrash} />

                    </TableCell>

                    {notidata.show ? <Popupnoti setshow={setnotidata} dispatchfun={row.type == "Paid" ? fetchwitxn : fetchtxn} show={notidata.show} msg={notidata.msg} success={notidata.success} direction={row.type == "Paid" ? '/withhistory' : row.type == 'Pending' ? '/pendingtxnhistory' : '/txnhistory'} /> : null}






                  </TableRow>
             
  )
}

export default Txndatatablecard