import { SET_TXN, SET_WITXN } from "../actiontypes/usertypes";

export const productsInitialState = [];

const witxnReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_WITXN:
        return {...action.payload};

        // case SET_WITXN:
        //     return [action.payload]
      default:
        return state;
    }
  };
  
  export default witxnReducer;