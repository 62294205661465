import { ADD_TXN, SET_PERUSER, SET_TXN, SET_WITXN } from "../actiontypes/usertypes";

export const productsInitialState = [];

const txnReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_TXN:
        return {...action.payload};

        case ADD_TXN:
            return [action.payload]

            // case SET_PERUSER:
            // return [action.payload]
      default:
        return state;
    }
  };
  
  export default txnReducer;