import { SET_USERS } from "../actiontypes/usertypes";

export const productsInitialState = [];

const userReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_USERS:
        return {...action.payload};
      default:
        return state;
    }
  };
  
  export default userReducer;