import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import Mybtn from './Mybtn'
import { fetchtxn, fetchtxnuser } from '../redux/actions/txnaction'
import { fethusers } from '../redux/actions/usersaction'
import { fethOffers } from '../redux/actions/offeraction'
import { fethbasic } from '../redux/actions/basicaction'
import { fethcategory } from '../redux/actions/categoryaction'
import { fethclicks } from '../redux/actions/clickaction'
import { fethdeals } from '../redux/actions/dealaction'
import { fethstores } from '../redux/actions/storeaction'
import { fetchwitxn } from '../redux/actions/witxnaction'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import Loadingmy from './Shortcom/Loadingmy'
import { fetchpendingtxn } from '../redux/actions/txnpendingaction'

const Navigationslid = () => {

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)

    const handlerefershclk = async () => {
        await setloading(true)
        await dispatch(fetchtxn(0, 15, '', ''))
        dispatch(fetchpendingtxn(0, 15))
        await dispatch(fethusers(0, 15, '', ''))
        await dispatch(fethOffers())
        await dispatch(fethbasic())
        await dispatch(fethcategory())
        await dispatch(fethclicks(1,15))
        await dispatch(fethdeals())
        await dispatch(fethstores())
        dispatch(fetchwitxn(0, 15))

        await setloading(false)





    }



    return (
        <div>

            {loading && <Loadingmy />}

            <div class="navigation">
                <ul>
                    <li>
                        <a href="#">
                            <span class="icon">
                                {/* <ion-icon name="logo-apple"></ion-icon> */}
                            </span>

                            <p class="title">GwalaxPe Admin</p>


                        </a>
                    </li>

                    <Mybtn title={'refresh'} onClick={handlerefershclk} />

                    <li>
                        <Link to='/dashboard'>
                            <span class="icon">
                                <ion-icon name="home-outline"></ion-icon>
                            </span>
                            <span class="title">Dashboard</span>
                        </Link>

                    </li>

                    <li>
                        <Link to='/basic'>
                            <span class="icon">
                                <ion-icon name="settings-outline"></ion-icon>
                            </span>
                            <span class="title">App Settings</span>
                        </Link>

                    </li>

                    <li>
                        <Link to='/users'>
                            <span class="icon">
                                <ion-icon name="people-outline"></ion-icon>
                            </span>
                            <span class="title">Users</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/categorys'>
                            <span class="icon">
                                <ion-icon name="color-filter-outline"></ion-icon>
                            </span>
                            <span class="title">Categories</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/offers'>
                            <span class="icon">

                                <ion-icon name="storefront-outline"></ion-icon>


                            </span>
                            <span class="title">Offers</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/stores'>
                            <span class="icon">

                                <ion-icon name="briefcase-outline"></ion-icon>


                            </span>
                            <span class="title">Stores</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/deals'>
                            <span class="icon">

                                <ion-icon name="briefcase-outline"></ion-icon>


                            </span>
                            <span class="title">Deals</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/addtxn'>
                            <span class="icon">

                                <ion-icon name="add-circle-outline"></ion-icon>


                            </span>
                            <span class="title">Add Transaction</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/banners'>
                            <span class="icon">

                                <ion-icon name="images-outline"></ion-icon>


                            </span>
                            <span class="title"> All Banners</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/txnhistory'>
                            <span class="icon">

                                <ion-icon name="newspaper-outline"></ion-icon>



                            </span>
                            <span class="title">Transaction History</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/pendingtxnhistory'>
                            <span class="icon">

                                <ion-icon name="newspaper-outline"></ion-icon>



                            </span>
                            <span class="title">Pending Txn History</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/withhistory'>
                            <span class="icon">

                                <ion-icon name="clipboard-outline"></ion-icon>



                            </span>
                            <span class="title">Withdrwal History</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/scratchcard'>
                            <span class="icon">

                                <ion-icon name="clipboard-outline"></ion-icon>



                            </span>
                            <span class="title">Scratch Cards</span>
                        </Link>
                    </li>



                    <li>
                        <Link to='/clicks'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">Clicks</span>
                        </Link>
                    </li>





                    <li>
                        <Link to='/notification'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">Send Notification</span>
                        </Link>
                    </li>


                    <li>
                        <Link to='/pmcode'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">Promocodes</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/iplteams'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">IPL TEAM</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/iplmatches'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">IPL MATCHES</span>
                        </Link>
                    </li>

                    <li>
                        <Link to='/iplpredicts'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">IPL PREDICTS</span>
                        </Link>
                    </li>


                    {/* <li>
                        <Link to='/stats'>
                            <span class="icon">

                                <ion-icon name="disc-outline"></ion-icon>



                            </span>
                            <span class="title">Stats</span>
                        </Link>
                    </li> */}



                    <li>
                        <a href="#">
                            <span class="icon">
                                <ion-icon name="log-out-outline"></ion-icon>
                            </span>
                            <span class="title">Sign Out</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navigationslid