import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import {useDispatch,useSelector} from 'react-redux'
import { fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethOffers } from '../../redux/actions/offeraction'
import SendnotifiAll from '../../Components/SendnotifiAll'
import NotificationTest from '../../Components/NotificationTest'

const Addoffer = () => {

  const {id} = useParams()

  const dispatch = useDispatch()

  const state5 = useSelector((state)=>state.selectedReducer)
  
  // console.log(allfcmtokens)

  const token = localStorage.getItem('token')
const [sendnoti,setsendnoti] = useState('')



const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    name:'',
    icon:'',
    amount:'',
    sortdes:'',
    fulldes:'',
    url:'',
    category:'',
    isactive:'',
    packagename:'',
    about:'',
    formurl:'',
    partner:'',
    shareandearn:'',
    bigimg:'',
    sendnoti:'',
    shareandearndailiylimit:'',
    shareandearnmaxlimit:''
})



const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}




const callingAPI = ()=>{
  dispatch(fethselectedoffer(id))
}

const updateForm = async ()=>{
        
  const userData = await state5.filter((val)=>val.id==id)
  
  userData.map((val)=>setmydta({name:val.name,
  icon:val.icon,
  amount:val.amount,
  sortdes:val.sortdes,
  fulldes:val.fulldes,
  url:val.url,
  category:val.category,
  isactive:val.isactive,
  packagename:val.packagename,
  about:val.about,
  formurl:val.formurl,
  partner:val.partner,
  shareandearn:val.shareandearn,
  bigimg:val.bigimg,
  sendnoti:mydta.sendnoti,
  shareandearndailiylimit:val.shareandearndailiylimit,
  shareandearnmaxlimit:val.shareandearnmaxlimit

}))

 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}

// console.log(allfcmtokens)






const handlBtnClk = ()=>{
  
  
   

    id?gpeapi.put(`/updatoffer/${id}`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    :gpeapi.post('/addoffer',mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then(async (res)=>{
    setnotidata({msg:res.data.msg,show:true,success:res.data.success})
    
  }).catch((e)=>console.log(e))


  // console.log(mydta)
  }









// console.log(state5[0])
const match = state5[0]?.id==id

useEffect(()=>{
  id && callingAPI()
  updateForm()
},[match,id])





  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={'Offer Name'} placeholder={'Please Enter Offer Name'} name='name' value={mydta.name} onChange={handleChnage}  />
        <Inputcom  label={'Offer Amount'} placeholder={'Please Enter Offer Amount'} name='amount' value={mydta.amount} onChange={handleChnage}/>
        <Inputcom label={'Offer Url'} placeholder={'Please Enter Offer URL'} name='url' value={mydta.url} onChange={handleChnage}/>

        <Inputcom label={'Form Url'} placeholder={'Please Enter Form URL'} name='formurl' value={mydta.formurl} onChange={handleChnage}/>
        <Inputcom label={'Offer Category'} placeholder={'Please Enter Offer Category'} name='category' value={mydta.category} onChange={handleChnage}/>
        <Inputcom label={'Offer Packagename'} placeholder={'Please Enter Offer Packagename'} name='packagename' value={mydta.packagename} onChange={handleChnage}/>
        <Inputcom label={'Offer Icon Url'} placeholder={'Please Enter Offer icon URL'} name='icon' value={mydta.icon} onChange={handleChnage}/>
        {<div className='flex justify-center  '>
          <img className='bg-red-200 rounded-md' src={mydta.icon?mydta.icon:'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{width:300 , height:100,padding:3}}/>
          </div>}

          <Inputcom label={'Offer Big Icon Url'} placeholder={'Please Enter Offer Big icon URL'} name='bigimg' value={mydta.bigimg} onChange={handleChnage}/>
        {<div className='flex justify-center  '>
          <img className='bg-red-200 rounded-md' src={mydta.bigimg?mydta.bigimg:'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{width:300 , height:100,padding:3}}/>
          </div>}

        <Inputcom label={'Partner Name'} placeholder={'Please Enter Partner Name'} name='partner' value={mydta.partner} onChange={handleChnage}/>

        <Textareacom label='About Offer' placeholder='Please Enter About this Offer' name='about' value={mydta.about} onChange={handleChnage}/>

        <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage}/>
        <Textareacom label='Full Steps' placeholder='Please Enter All Steps' name='fulldes' value={mydta.fulldes} onChange={handleChnage}/>
        {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}
        
        {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

        <Selectcom label={'Share & Earn'} data={['true','false']} name='shareandearn' onChange={handleChnage} value={mydta.shareandearn}/>

        <Inputcom  label={'Daily Limit'} placeholder={'Please Enter Daily Limit'} name='shareandearndailiylimit' value={mydta.shareandearndailiylimit} onChange={handleChnage}/>

        <Inputcom  label={'Offer Max Limit'} placeholder={'Please Enter Max Limit'} name='shareandearnmaxlimit' value={mydta.shareandearnmaxlimit} onChange={handleChnage}/>


        <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/>

        {<Selectcom label={'Send Notification ?'} data={['true','false']} name='sendnoti' onChange={handleChnage} value={mydta.sendnoti}/>}

        

        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/offers'} dispatchfun={fethOffers}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE OFFER':'ADD OFFER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>


       

        


        



    </div>
  )
}

export default Addoffer