import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Txndatatablecom from '../../Components/Txndatatablecom'
import { fetchtxn } from '../../redux/actions/txnaction'
import { useState } from 'react'
import { Spinner } from 'react-rainbow-components'
import Btnwithicon from '../../Components/Btnwithicon'
import Pagination from '../../Components/Pagination'
import { fetchpendingtxn } from '../../redux/actions/txnpendingaction'
import Paginationnew from '../../Components/Paginationnew'
import Mybtn from '../../Components/Mybtn'

const Pendingtxn = () => {

  const txnData = useSelector((state) => state.txnpendingReducer)

  
  const newdatat = txnData?.TotalUsers?.docs
  const [loadingmmm, setloadingmmm] = useState(false)


  const paginate = async (number) => {
    setloadingmmm(true)
    await dispatch(fetchpendingtxn(number , 15))
    setloadingmmm(false)
  }



  const dispatch = useDispatch()
  const [referesloading, setreferesloading] = useState(false)

  const reloadAction = async () => {
    setreferesloading(true)
    await dispatch(fetchtxn(0, 15, '', ''))
    setreferesloading(false)

  }




  return (
    <div>

      <Mybtn color={'#2a2185'} title={referesloading ? <Spinner size="small" type="arc" variant="brand" /> : '↻'} onClick={reloadAction} />

      {
        loadingmmm ?
          <Spinner size="small" type="arc" variant="brand" /> :
          <Txndatatablecom ispending={true} data={txnData} />
      }



      <Paginationnew perPage={txnData?.TotalUsers?.limit} totalData={txnData?.TotalUsers?.total} paginate={paginate} currentPage={txnData?.TotalUsers?.page} />

    </div>
  )
}

export default Pendingtxn