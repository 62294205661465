import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Mybtn from '../../Components/Mybtn'
import Textcompo from '../../Components/Shortcom/Textcompo'
import Clickstable from '../../Components/Tablesdata/Clickstable'
import Refertable from '../../Components/Tablesdata/Refertable'
import Txndatatablecom from '../../Components/Txndatatablecom'
import Scratchcardtable from '../../Components/Tablesdata/Scratchcardtable'
import gpeapi from '../../api/gpeapi'

const Viewuser = () => {
    const token = localStorage.getItem('token')

    const navigate = useNavigate() 

    const [referdata,setreferdata] = useState()
    const [clickdata,setclickdata] = useState()
    const [txndata,settxndata] = useState()

    const { id } = useParams()

    const [loadingmm,setloadingmy] = useState(false)
    const [clickloading,setclickloading] = useState(false)
    const [txnloading,settxnloading] = useState(false)


    useEffect(()=>{
        callforidupdate(0,15)
        callforclickupdate(0,15)
        callfortxnupdate(0,15)
    },[id])

    const callforidupdate = async (page,limit)=>{
        setloadingmy(true)
        const res = await gpeapi.get(`/getuserreferviapagi?page=${page}&limit=${limit}&userid=${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res)=>setreferdata(res.data)).catch((e)=>setloadingmy(false))
        setloadingmy(false)

    }

    const callforclickupdate =async (page,limit)=>{
        setclickloading(true)
        const res = await gpeapi.get(`/getusercliksviapagi?page=${page}&limit=${limit}&userid=${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res)=>{
            setclickdata(res.data)
            
        }).catch((e)=>setclickloading(false))
        setclickloading(false)
    }

    const callfortxnupdate =async (page,limit)=>{
        settxnloading(true)
        const res = await gpeapi.get(`/getusertxndataviapagi?page=${page}&limit=${limit}&userid=${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res)=>settxndata(res.data)).catch((e)=>settxnloading(false))
        settxnloading(false)
    }



    const myMaindata = useSelector((state) => state.userReducer)

    const userData = myMaindata?.TotalUsers?.docs.filter((val) => val.id == id)[0]
    // const userclicks = useSelector((state) => state.clickReducer).filter((val) => val.userid == id)
    const usertxn = useSelector((state) => state.txnReducer)?.TotalUsers?.docs
    const userscratchtxn = useSelector((state) => state.scratchReducer).filter((val) => val.userid == id)

    // const useralldata = useSelector((state) => state.userReducer).filter((val) => val.referby == userData.refercode)



    const paidamt = () => {
        const withdrwadataamt = txndata?.TotalUsers?.docs?.filter((val) => val.type == 'Paid' && val.status == 'Successful')
        if (withdrwadataamt?.length != 0) {
            const newamt = withdrwadataamt?.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0

    }

    const pendigamt = () => {
        const withdrwadataamt = txndata?.TotalUsers?.docs?.filter((val) => val.type !== 'Paid' && val.status == 'Pending')
        if (withdrwadataamt?.length != 0) {
            const newamt = withdrwadataamt?.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0
    }

    
    const rejectamt = () => {
        const withdrwadataamt = txndata?.TotalUsers?.docs?.filter((val) => val.type !== 'Paid' && val.status == 'Rejected')
        if (withdrwadataamt?.length != 0) {
            const newamt = withdrwadataamt?.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0
    }

    const pendingwithamt = () => {
        const withdrwadataamt = txndata?.TotalUsers?.docs?.filter((val) => val.type == 'Paid' && val.status == 'Pending')
        if (withdrwadataamt?.length != 0) {
            const newamt = withdrwadataamt?.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0
    }

    const totalearning = () => {

        if (usertxn?.length != 0) {
            const walletto = txndata?.TotalUsers?.docs?.map((val) => val.type !="Paid" && val.amount).reduce((pre, cur) => pre + cur)

            return walletto
        }
        return 0
    }




    const [option, setoption] = useState('clicks')


    const listitemtab = [
        { value: 'clicks', label: 'Click Activity' },
        { value: 'txn', label: 'Cashback Activity' },
        { value: 'scratch', label: 'Scratch Cards' },
        { value: 'refer', label: 'Referral Network' },
    ]

    const showfulldatetime = (pdate) => {
        const mydate = new Date(pdate)
        var date = mydate.getDate()
        var month = mydate.getMonth()
        var year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
      
        var strTime = `${date}-${month+1}-${year} ${hours}:${minutes} ${ampm}`

        return strTime

    }

    const addpayment = ()=>{
        navigate('/addtxn',{state:{uid:id}})
    }

    const [txnloadinghgg,settxnloadinghgg] = useState(false)

    const paginatefun =async (num)=>{
        settxnloadinghgg(true)
       await callfortxnupdate(num,15)
        settxnloadinghgg(false)

    }

   

    return (
        <div className='maindiv'>

            <div className='profilecard1'>

                <div style={{ display: 'block', justifyContent: 'center', }}>
                    <img className='profileimg' src='https://www.cashpakar.com/web/img/default-avatar.png' />
                    <p className='paragraphprofile'>{id}</p>
                    <p className='paragraphprofile'>{referdata?.mydata?.name}</p>
                    <p className='paragraphprofile'>{referdata?.mydata?.mobile}</p>
                    <p className='paragraphprofile'>{referdata?.mydata?.email}</p>
                    {referdata?.mydata?.lastlogin && <p className='paragraphprofile'>Last Login: {referdata?.mydata?.lastlogin == 'active' ? 'active' : showfulldatetime(referdata?.mydata?.lastlogin)}</p>}
                    <p className='paragraphprofile'>{referdata?.mydata?.city} {referdata?.mydata?.state}</p>



                </div>

                <Textcompo text={'Total Earning'} subtext={`₹${totalearning()?.toFixed(2)}`} bgcolor={'#00a65a'} />
                <Textcompo text={'Current Balance'} subtext={`₹${referdata?.mydata?.wallet}`} bgcolor={'#3c8dbc'} />
                <Textcompo text={'Pending Cashback'} subtext={`₹${pendigamt()?.toFixed(2)}`} bgcolor={'#be8f45'} />
                <Textcompo text={'Rejected Cashback'} subtext={`₹${rejectamt()}`} bgcolor={'red'} />
                <Textcompo text={'Withdraw Pending'} subtext={`₹${pendingwithamt()}`} bgcolor={'#e65b32'} />
                <Textcompo text={'Paid Cash'} subtext={`₹${paidamt()?.toFixed(2)}`} bgcolor={'#00a65a'} />

                <Textcompo text={'Referral Code'} subtext={referdata?.mydata?.refercode} bgcolor={'#b00cf4'} />
                <Textcompo text={'Referral Via'} subtext={referdata?.mydata?.referby} bgcolor={'#740cf4'} />

                <Mybtn title={'Add Payment'} bgcolor={'#3c8dbc'} color={'white'} onClick={addpayment}/>




            </div>


            <div className='profilecard2'>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        listitemtab.map((val, i) => (
                            <div key={i} className='optionactivity' onClick={() => setoption(val.value)} style={{ cursor: 'pointer', borderTop: val.value == option && '3px solid #3c8dbc' }}>
                                <p style={{ cursor: 'pointer' }}>{val.label}</p>
                            </div>
                        ))
                    }

                </div>

                {
                    option == 'clicks' ? (<Clickstable paginattee={callforclickupdate} loading={clickloading} data={clickdata} />) : option == 'txn' ? (<Txndatatablecom data={txndata} mypaginatefun={paginatefun} mainmyloading={txnloadinghgg}/>) : option=='scratch'? <Scratchcardtable data={userscratchtxn}/> : <Refertable loading={loadingmm} paginattee={callforidupdate} data={referdata} />
                }




                {/* <Clicksactivitytable/> */}

            </div>



        </div>
    )
}

export default Viewuser