
import './App.css';
import './globle.css';
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";


import Navigationslid from './Components/Navigationslid';
import Main from './pages/Dashboard/Main';
import Header from './Components/Header';
import Usermain from './pages/Users/Usermain';
import {useDispatch,useSelector} from 'react-redux'
import { fethusers } from './redux/actions/usersaction';
import { fethOffers } from './redux/actions/offeraction';
import Mainoffer from './pages/Offers/Mainoffer';
import Addoffer from './pages/Offers/Addoffer';
import Maintxn from './pages/Txnhistory/Maintxn';
import { fetchtxn } from './redux/actions/txnaction';
import { fetchwitxn } from './redux/actions/witxnaction';

import Mainwithdrwa from './pages/Txnhistory/Mainwithdrwa';
import Addtxn from './pages/Txnhistory/Addtxn';
import Adduser from './pages/Users/Adduser';
import Bannermain from './pages/Banners/Bannermain';
import {fethbanners} from './redux/actions/banneraction'
import Addbanner from './pages/Banners/Addbanner';
import Basicmain from './pages/BasicDetails/Basicmain';
import { fethbasic } from './redux/actions/basicaction';
import Addbasic from './pages/BasicDetails/Addbasic';
import Mainlogin from './pages/Login/Mainlogin';
import { useState } from 'react';
import gpeapi from './api/gpeapi';
import Addstore from './pages/Store/Addstore';
import Mainstore from './pages/Store/Mainstore';
import { fethstores } from './redux/actions/storeaction';
import { fethcategory } from './redux/actions/categoryaction';
import Maincategory from './pages/Category/Maincategory';
import Addcategory from './pages/Category/Addcategory';
import { fethdeals } from './redux/actions/dealaction';
import Maindeal from './pages/Deals/Maindeal';
import Adddeal from './pages/Deals/Adddeal';
import Mainclicks from './pages/Clicks/Mainclicks';
import { fethclicks } from './redux/actions/clickaction';
import Viewuser from './pages/Users/Viewuser';
import Pendingtxn from './pages/Txnhistory/Pendingtxn';
import Mainnotification from './pages/Notification/Mainnotification';
import Addnotification from './pages/Notification/Addnotification';
import Mainpmcode from './pages/Pmcode/Mainpmcode';
import Addpmcode from './pages/Pmcode/Addpmcode';
import { fethPromocode } from './redux/actions/promoaction';
import Viewpmcode from './pages/Pmcode/Viewpmcode';
import Mainclone from './pages/Cloned/Mainclone';
import Addclone from './pages/Cloned/Addclone';
import Mainanala from './pages/Analatics/Mainanala';
import MainiplTeam from './pages/IPL/IplTeams/MainiplTeam';
import Addiplteam from './pages/IPL/IplTeams/Addiplteam';
import { fethiplmatches } from './redux/actions/iplmatches';
import { fethiplteams } from './redux/actions/iplteams';
import Mainiplmatch from './pages/IPL/IplMatches/Mainiplmatch';
import Addiplmatch from './pages/IPL/IplMatches/Addiplmatch';
import { fethiplpredicts } from './redux/actions/iplpredictaction';
import Addiplpredict from './pages/IPL/IplPredicts/Addiplpredict';
import Mainiplpredicts from './pages/IPL/IplPredicts/Mainiplpredicts';
import Mainscratchcard from './pages/Scratchcard/Mainscratchcard';
import Addscratchcard from './pages/Scratchcard/Addscratchcard';
import { fetchscratchcard } from './redux/actions/scratchcardaction';
import { fetchpendingtxn } from './redux/actions/txnpendingaction';


function App() {

  const token = localStorage.getItem('token')
  const [tokenvalid,settokenvalid] = useState(null)

 

  const dispatch = useDispatch()


  useEffect(()=>{
    checkvalidtoken()
  },[])


  const checkvalidtoken = ()=>{
    gpeapi.get('/checktoken',{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>{
    if(res.data.success){
      settokenvalid(true)
    }else{
      settokenvalid(false)
    }


  }).catch((e)=>console.log('error'))
  }


  useEffect(()=>{
    dispatch(fethusers(0,15,'',''))
    dispatch(fethOffers())
    dispatch(fetchtxn(0,15,'',''))
    dispatch(fetchpendingtxn(0,15))
    dispatch(fetchwitxn(0,15))
    dispatch(fethbanners())
    dispatch(fethbasic())
    dispatch(fethstores())
    dispatch(fethcategory())
    dispatch(fethdeals())
    dispatch(fethclicks(1,15))
    dispatch(fethPromocode())
    dispatch(fethiplmatches())
    dispatch(fethiplteams())
    dispatch(fethiplpredicts())
    dispatch(fetchscratchcard())
   
    


    
},[])

  return (

    <Router>


      {
        token?(
          <div className="App">
          <Navigationslid />
  
  
          <div>
            
            <Routes>
            
              <Route path='/' element={<Main />} />
              <Route path='/dashboard' element={<Main />} />
              <Route path='/users' element={<Usermain />} />
              <Route path='/offers' element={<Mainoffer />} />
              <Route path='/stores' element={<Mainstore />} />
              <Route path='/deals' element={<Maindeal />} />
              <Route path='/categorys' element={<Maincategory />} />
              <Route path='/banners' element={<Bannermain />} />
              <Route path='/txnhistory' element={<Maintxn />} />
              <Route path='/basic' element={<Basicmain />} />
              <Route path='/clicks' element={<Mainclicks />} />
              <Route path='/pendingtxnhistory' element={<Pendingtxn />} />
              <Route path='/notification' element={<Mainnotification />} />
              <Route path='/pmcode' element={<Mainpmcode />} />
              <Route path='/cloned' element={<Mainclone />} />
              <Route path='/stats' element={<Mainanala />} />
              <Route path='/iplteams' element={<MainiplTeam />} />
              <Route path='/iplmatches' element={<Mainiplmatch />} />
              <Route path='/iplpredicts' element={<Mainiplpredicts />} />
              <Route path='/scratchcard' element={<Mainscratchcard />} />

              
  
  
              <Route path='/addnotification' element={<Addnotification />} />
              <Route path='/addscratchcard' element={<Addscratchcard />} />
              <Route path='/addiplpredicts' element={<Addiplpredict />} />
              <Route path='/addiplteams' element={<Addiplteam />} />
              <Route path='/addiplmatches' element={<Addiplmatch />} />
              <Route path='/addpmcode' element={<Addpmcode />} />
              <Route path='/addcloned' element={<Addclone />} />
              <Route path='/addoffer' element={<Addoffer />} />
              <Route path='/addstore' element={<Addstore />} />
              <Route path='/adddeal' element={<Adddeal />} />
              <Route path='/addcategory' element={<Addcategory />} />
              <Route path='/addtxn' element={<Addtxn />} />
              <Route path='/adduser' element={<Adduser />} />
              <Route path='/addbanner' element={<Addbanner />} />
              <Route path='/updatetxn/:id' element={<Addtxn />} />
              <Route path='/updateoffer/:id' element={<Addoffer />} />
              <Route path='/updatestore/:id' element={<Addstore />} />
              <Route path='/updatedeal/:id' element={<Adddeal />} />
              <Route path='/updatecategory/:id' element={<Addcategory />} />
              <Route path='/updateuser/:id' element={<Adduser />} />
              <Route path='/viewuser/:id' element={<Viewuser />} />
              <Route path='/viewpmcode/:id' element={<Viewpmcode />} />

              <Route path='/updatebanner/:id' element={<Addbanner />} />
              <Route path='/updatebasic' element={<Addbasic />} />
              <Route path='/updatepmcode/:id' element={<Addpmcode />} />
              <Route path='/updateiplteam/:id' element={<Addiplteam />} />
              <Route path='/updateiplmatches/:id' element={<Addiplmatch />} />
              <Route path='/updateiplpredicts/:id' element={<Addiplpredict />} />
              <Route path='/updatescratchcard/:id' element={<Addscratchcard />} />
  
  
              <Route path='/withhistory' element={<Mainwithdrwa />} />
              
  
            </Routes>
          </div>
  
  
        </div>
        ):(
          <Routes>
            
          <Route path='/login' element={<Mainlogin/>}/>
          <Route path='/' element={<Mainlogin/>}/>
          <Route path='*' element={<Mainlogin/>}/>
        </Routes>
        )
      }

     



     




    </Router>
  );
}

export default App;
