import React from 'react'
import LineChartsDash from '../Charts/LineChartsDash'

const Mainanala = () => {
  return (
    <div className='container'>
        <LineChartsDash/>
    </div>
  )
}

export default Mainanala