import React from 'react'
import DashCard from '../../Components/DashCard'
import { useSelector } from 'react-redux'

const Dashuper = ({userdata,offerdata,bannerData,todayclick,yesclicks,stores,todayuser,yesuser,activeuser,totalcb,approvedcb,pendingcb,rejectcb,withdrwapending,withdone}) => {

    const userDatata = useSelector((state)=>state.userReducer)
    const clickDatata = useSelector((state)=>state.clickReducer)
    const txnDatata = useSelector((state)=>state.txnReducer)
  const withdrwatxnData = useSelector((state) => state.witxnReducer)


    // console.log(userDatata)
   
    return (
        <>

{
    userDatata?.allNumbers?.map((val,i)=>(
        <DashCard number={val.value} title={val.title} icon={'person-outline'} />

    ))
}
<DashCard number={offerdata.length} title='Total Offers' icon={'storefront-outline'} />
            {/* <DashCard number={bannerData.length} title='Total Banners' icon={'images-outline'} /> */}
            <DashCard number={stores.length} title='Total Stores' icon={'briefcase-outline'} />

{
    clickDatata?.allNumbers?.map((val,i)=>(
        <DashCard number={val.value} title={val.tilte} icon={'person-outline'} />

    ))
}

{
    txnDatata?.allNumbers?.map((val,i)=>(
        <DashCard number={`${val.value?val.value:0}₹`} title={val.tilte} icon={'person-outline'} />

    ))
}

{
    withdrwatxnData?.allNumbers?.map((val,i)=>(
        <DashCard number={`${val.value}₹`} title={val.tilte} icon={'person-outline'} />

    ))
}


           
         
        </>


    )
}

export default Dashuper