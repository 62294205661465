import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Selectcom from '../../Components/Selectcom'
import Textareacom from '../../Components/Textareacom'
import { fethselecteduser } from '../../redux/actions/selectedaction'
import { fethusers } from '../../redux/actions/usersaction'

const Adduser = () => {

    const {id} = useParams()

    const dispatch = useDispatch()
  
    const state5 = useSelector((state)=>state.selectedReducer)
  
    const token = localStorage.getItem('token')
  
  const [notidata, setnotidata] = useState({
    msg:'',
    success:'',
    show:false
  })
  
    const [mydta,setmydta]= useState({
      name:'',
      email:'',
      password:'',
      mobile:'',
      isactive:'',
      role:'',
      wallet:'',
      referby:'',
      refercode:'',
      refermoney:'',
      
  })
  
  const handleChnage = (e)=>{
    const name = e.target.name
    const value = e.target.value
  
    setmydta({...mydta,[name]:value})
  }
  
  
  
  
  const callingAPI = ()=>{
    dispatch(fethselecteduser(id))
  }
  
  const updateForm = async ()=>{
          
    const userData = await state5.filter((val)=>val.id==id)
    
    userData.map((val)=>setmydta(val))
    
   
    // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
    
  }


  
  
  const handlBtnClk = ()=>{
     
  
      id?gpeapi.put(`/updateuser/${id}`,mydta,{headers:{
        "Authorization":`Beaer ${token}`
    }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>setnotidata({msg:e?.response?.data?.msg,show:true,success:e.response.data.success}))
      :gpeapi.post('/signup',mydta,{headers:{
        "Authorization":`Beaer ${token}`
    }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>setnotidata({msg:e?.response?.data?.msg,show:true,success:e.response.data.success}))
  
    // console.log(mydta)
    }
  
  
  
  
  
  
  
  
  
  // console.log(state5[0])
  const match = state5[0]?.id==id
  
  useEffect(()=>{
    id && callingAPI()
    updateForm()
  },[match,id])


  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={'User Name'} placeholder={'Please Enter Offer Name'} name='name' value={mydta.name} onChange={handleChnage}  />
        <Inputcom  label={'User email'} placeholder={'Please Enter User email'} name='email' value={mydta.email} onChange={handleChnage}/>
        <Inputcom label={'User password'} placeholder={'Please Enter User password'} name='password' value={mydta.password} onChange={handleChnage}/>
        <Inputcom label={'User mobile'} placeholder={'Please Enter User mobile'} name='mobile' value={mydta.mobile} onChange={handleChnage}/>
        <Inputcom label={'User referby'} placeholder={'Please Enter User referby'} name='referby' value={mydta.referby} onChange={handleChnage}/>
        <Inputcom label={'User refercode'} placeholder={'Please Enter User refercode'} name='refercode' value={mydta.refercode} onChange={handleChnage}/>
        <Inputcom label={'User refermoney'} placeholder={'Please Enter refermoney'} name='refermoney' value={mydta.refermoney} onChange={handleChnage}/>
        <Inputcom label={'User role'} placeholder={'Please Enter role'} name='role' value={mydta.role} onChange={handleChnage}/>
        <Inputcom label={'User wallet'} placeholder={'Please Enter wallet'} name='wallet' value={mydta.wallet} onChange={handleChnage}/>
        {/* <Textareacom label='Full Steps' placeholder='Please Enter All Steps' name='fulldes' value={mydta.fulldes} onChange={handleChnage}/> */}
        {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}
        
        {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

        <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/>

        

        <Popupnoti  show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/users'} dispatchfun={fethusers}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE USER':'ADD USER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>

        


        



    </div>
  )
}

export default Adduser