import { SET_IPLMATCHES, SET_IPLPREDICTS } from "../actiontypes/usertypes";

export const productsInitialState = [];

const iplpredictReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_IPLPREDICTS:
        return [...action.payload];

        // case UPDATE_OFFERS:
        //     return [action.payload]
      default:
        return state;
    }
  };
  
  export default iplpredictReducer;