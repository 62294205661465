import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Dashuper from './Dashuper'
import { useDispatch, useSelector } from 'react-redux'
import { fethusers } from '../../redux/actions/usersaction'
import { fethOffers } from '../../redux/actions/offeraction'
import { fethclicks } from '../../redux/actions/clickaction'
import Loadingmy from '../../Components/Shortcom/Loadingmy'
import Mybtn from '../../Components/Mybtn'
import { fetchtxn } from '../../redux/actions/txnaction'
import { fethstores } from '../../redux/actions/storeaction'
import { fetchwitxn } from '../../redux/actions/witxnaction'
import gpeapi from '../../api/gpeapi'
import Newpagination from '../../Components/Newpagination'
import LineChartsDash from '../Charts/LineChartsDash'
import Mainoffchart from '../Charts/OfferCharts/Mainoffchart'
import { fetchpendingtxn } from '../../redux/actions/txnpendingaction'

const Main = () => {
    const token = localStorage.getItem('token')

    // const dispatch = useDispatch()

    // useEffect(()=>{
    //     dispatch(fethclicks())

    // },[])

    const [tokenvalid, settokenvalid] = useState(null)

    useEffect(() => {
        checkvalidtoken()
    }, [])


    const checkvalidtoken = () => {
        gpeapi.get('/checktoken', {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                settokenvalid(true)
            } else {
                settokenvalid(false)
            }


        }).catch((e) => console.log('error'))
    }


    if (tokenvalid == null) {

    } else if (tokenvalid == false) {
        localStorage.clear()
        window.location.reload()
    }



    const [toggle, settoggle] = useState(false)

    const teststate = useSelector((state) => state.userReducer)


    const state = teststate?.TotalUsers?.docs
    
    const state1 = useSelector((state) => state.offerReducer)

    // const testtstate2 = useSelector((state) => state.txnReducer)
    // const state2 = testtstate2?.TotalUsers?.docs

    // const state3 = useSelector((state) => state.witxnReducer)
    const BannerData = useSelector((state) => state.bannerReducer)

    const Stores = useSelector((state) => state.storeReducer)

    // const testclickData = useSelector((state) => state.clickReducer)
    // const clickData = testclickData?.offerlist?.docs



    const callanydate = (days) => {
        var mysevendays = new Date();
        mysevendays.setDate(mysevendays.getDate() - days);
        return mysevendays.toISOString().split('T')[0]
    }

    // const todayclicks = clickData?.filter((val) => val?.createdAt?.split('T')[0] == callanydate(0))
    // const yesterdayclicks = clickData?.filter((val) => val?.createdAt?.split('T')[0] == callanydate(1))
    // const todayuser = state?.filter((val) => val?.createdAt?.split('T')[0] == callanydate(0))
    // const yesterdayusers = state?.filter((val) => val?.createdAt?.split('T')[0] == callanydate(1))
    // const activeonappusers = state?.filter((val) => val.lastlogin == 'active')


    // const totalcb = () => {
    //     const datawith = state2 && state2?.filter((val) => val.type !== 'Paid').map((val) => val.amount)
    //     if (datawith?.length > 0) {
    //         const reduccc = datawith?.reduce((prev, curr) => prev + curr).toFixed(1)
    //         return reduccc

    //     }
    //     return 0
    // }

    // const approvedcb = () => {
    //     const datawith = state2 && state2?.filter((val) => val.type != "Paid" && val.status == "Successful").map((val) => val.amount)
    //     if (datawith?.length > 0) {
    //         const reduccc = datawith?.reduce((prev, curr) => prev + curr).toFixed(1)
    //         return reduccc

    //     }
    //     return 0
    // }

    // const pendingcb = () => {
    //     const datawith = state2 && state2?.filter((val) => val.type != "Paid" && val.status == "Pending").map((val) => val.amount)
    //     if (datawith?.length > 0) {
    //         const reduccc = datawith?.reduce((prev, curr) => prev + curr).toFixed(1)
    //         return reduccc

    //     }
    //     return 0
    // }

    // const rejectedcb = () => {
    //     const datawith = state2 && state2?.filter((val) => val.type != "Paid" && val.status == "Rejected").map((val) => val.amount)
    //     if (datawith?.length > 0) {
    //         const reduccc = datawith?.reduce((prev, curr) => prev + curr).toFixed(1)
    //         return reduccc

    //     }
    //     return 0
    // }








    // const checkwithdone = () => {
    //     const datawith = state2 && state2?.filter((val) => val.type == "Paid" && val.status == "Successful").map((val) => val.amount)
    //     if (datawith?.length > 0) {
    //         const reduccc = datawith?.reduce((prev, curr) => prev + curr).toFixed(1)
    //         return reduccc

    //     }
    //     return 0
    // }

    // const checkwithpending = () => {
    //     const datawith = state2 && state2?.filter((val) => val.type == "Paid" && val.status == "Pending").map((val) => val.amount)
    //     if (datawith?.length > 0) {
    //         const reduccc = datawith?.reduce((prev, curr) => prev + curr).toFixed(1)

    //         return reduccc


    //     }
    //     return 0
    // }

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)


    const handlerefere = async () => {
        await setloading(true)
        await dispatch(fetchtxn(0, 15, '', ''))
        dispatch(fetchpendingtxn(0, 15))
        await dispatch(fethusers(0, 15, '', ''))
        dispatch(fethOffers())
        dispatch(fethclicks())
        dispatch(fethstores())
        dispatch(fetchwitxn(0, 15))
        await setloading(false)
    }


   



    return (
        <div className={toggle ? "main active" : "main"}>
            <Header settoggle={settoggle} toggle={toggle} />
            {loading && <Loadingmy />}

            <Mybtn title={'Refresh'} onClick={handlerefere} />


            {/* <div className='container'>
    <LineChartsDash/>
</div> */}


            <div className='cardBox'>
                {state==undefined && <Loadingmy />}
                <Dashuper  offerdata={state1} bannerData={BannerData} stores={Stores} />
            </div>

            {/* <Newpagination/> */}

            <div className='container'>
                <Mainoffchart />
            </div>





        </div>
    )
}

export default Main