
import gpeapi from "../../api/gpeapi";
import NewtestPop from "../../Components/NewtestPop";
import Popupnoti from "../../Components/Popupnoti";
import { Myfun } from "../../Components/Testfun";
import { ADD_TXN, SET_PENDINGTXN, SET_PERUSER, SET_TXN, SET_WITXN } from "../actiontypes/usertypes";





const token = localStorage.getItem('token')

export const fetchpendingtxn = (page,limit)=>{

    return async function(dispatch){

        const res = await gpeapi.get(`/gettxnpendingviapagi?page=${page}&limit=${limit}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        const fillterdtoNew = res.data

        

        dispatch(setTxn(fillterdtoNew));

    }
}








export const setTxn = (data = null) => {
    if (data) {
        return {
            type: SET_PENDINGTXN,
            payload: data,
        };
    }

    return {
        type: SET_PENDINGTXN,
        payload: [],
    };
};






