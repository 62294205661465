import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-rainbow-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Selectcom from '../../../Components/Selectcom';
import Mybtn from '../../../Components/Mybtn';

const Viewparticipants = ({ data, show, updatestate, matchdata }) => {

    const scratchcarddata = useSelector((state) => state.scratchReducer).filter((val) => val.from == matchdata[0]?.matchid)

    const iplPredictAllData = useSelector((state) => state.iplpredictReducer)



    const dispatch = useDispatch()
    const [open, setopen] = useState(false)
    const testuserData = useSelector((state) => state.userReducer)
    const userData = testuserData?.TotalUsers?.docs

    const [teams, setteams] = useState([])

    const [filterteam, setfilterteam] = useState('ALL')

    const [mydata, setmydata] = useState([])



    const navigate = useNavigate()

    const handlebtnclk = () => {

        setopen(false)
        updatestate(false)

    }

    useEffect(() => {
        firstTimeCall()
        setmydata(data)
    }, [data])


    const firstTimeCall = () => {
        const temasss = matchdata[0]?.matchname?.split(' VS ')
        setteams(temasss)
        setfilterteam('ALL')



    }



    const firstinmi = () => {
        setopen(show)

    }

    useEffect(() => {
        firstinmi()
    }, [show])

    const handleview = (id) => {
        navigate(`/viewuser/${id}`)
    }

    const getpmamvia = (date) => {




        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;


        return `${todate}-${month + 1}-${year} ${strTime}`
    }

    const gettimeonly = (date) => {
        const mydate = new Date(date)

        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes;
        return strTime
    }

    const retunusername = (userid) => {
        const myuser = userData?.filter((val) => val.id == userid)[0]

        return myuser?.name?.slice(0, 10)
    }


    const handlefilterviateams = (e) => {
        setfilterteam(e)


    }

    const handlecheckclk = () => {
        const filterrdmyd = filterteam !== 'ALL' ? data?.filter((val) => val?.predictteam == filterteam) : data
        setmydata(filterrdmyd)
    }

    const checkTotalPredit = (useridd) => {

        const result = iplPredictAllData.filter((o) =>
            o?.participants?.some((tag) => tag?.userid == useridd)
        );



        return result?.length


    }




    return (
        <div >

            <Modal style={{ width: '60%', height: '90%' }} id="modal-2" isOpen={open} hideCloseButton={true} footer={

                <div className="flex justify-end">
                    <Button onClick={handlebtnclk} label={'OK'} variant="brand" />

                </div>
            }>
                <div className='flex flex-col items-center'>

                    <div style={{ display: 'flex' }}>

                        <div >
                            <div style={{ display: 'inline-flex', justifyContent: 'center', backgroundColor: '#343c49', color: 'white', padding: 7, borderRadius: 10, margin: 10, overflow: 'auto' }}>
                                <div style={{ margin: '0px 10px' }}>
                                    <p>Total Participants - {data?.length}</p>

                                </div>
                            </div>

                            {
                                filterteam !== 'ALL' &&
                                <div style={{ display: 'inline-flex', justifyContent: 'center', backgroundColor: '#343c49', color: 'white', padding: 7, borderRadius: 10, margin: 10 }}>
                                    <div style={{ margin: '0px 10px' }}>
                                        <p>{filterteam} - {mydata?.length}</p>

                                    </div>
                                </div>
                            }

                        </div>


                        <div >
                            <Selectcom label={'Filter Via Team'} data={teams && [...teams, 'ALL']} name='iswinnerdeclare' value={filterteam} onChange={(e) => handlefilterviateams(e.target.value)} />

                            <Mybtn title={'CHECK'} onClick={handlecheckclk} />
                        </div>



                    </div>






                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>USERID</strong></TableCell>
                                    <TableCell><strong>Name</strong></TableCell>
                                    {
                                        matchdata[0]?.iswinnerdeclare &&

                                        <TableCell><strong>AMT</strong></TableCell>
                                    }
                                    <TableCell ><strong>Predicted Team</strong></TableCell>
                                    <TableCell ><strong>Time</strong></TableCell>



                                    <TableCell align='right'><strong>View User</strong></TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mydata && mydata.sort((a, b) => { return gettimeonly(a.date) - gettimeonly(b.date) }).map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.userid}<p > <span style={{backgroundColor:'black',color:'white',padding:5,fontSize:14,borderRadius:5}}>{checkTotalPredit(row.userid)}</span> </p>
                                        </TableCell>

                                        {/* <TableCell >{row.matchname}({row.matchnumber})</TableCell> */}
                                        <TableCell >{retunusername(row?.userid)}</TableCell>
                                        {
                                            matchdata[0]?.iswinnerdeclare &&

                                            <TableCell> {matchdata[0]?.winningteam == row.predictteam && `${scratchcarddata?.filter((val) => val.userid == row?.userid)[0]?.amount}₹`}</TableCell>
                                        }

                                        <TableCell >{row.predictteam}</TableCell>

                                        <TableCell >{getpmamvia(row?.date)}</TableCell>





                                        <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', border: 0 }}>
                                            <FontAwesomeIcon className='usertablicon' color='yellowgreen' icon={faEye} onClick={() => handleview(row.userid)} />


                                        </TableCell>





                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>



            </Modal>


        </div>
    )
}

export default Viewparticipants