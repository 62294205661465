import { ADD_TXN, SET_BANNERS } from "../actiontypes/usertypes";

export const productsInitialState = [];

const bannerReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_BANNERS:
        return [...action.payload];

        // case ADD_TXN:
        //     return [action.payload]

            // case SET_PERUSER:
            // return [action.payload]
      default:
        return state;
    }
  };
  
  
  export default bannerReducer;



  // const addbannerreducer = (state=[],action)=>{
  //   if(action.type=='ADD'){
  //     return state+2
  //   }
  // }