import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-rainbow-components'
import { useDispatch, useSelector } from 'react-redux'
import DashCard from '../../Components/DashCard'
import Mybtn from '../../Components/Mybtn'
import Pagination from '../../Components/Pagination'
import Calendarmy from '../../Components/Shortcom/Calendarmy'
import Clickstable from '../../Components/Tablesdata/Clickstable'
import { fethclicks } from '../../redux/actions/clickaction'


const Mainclicks = ({ propdata }) => {

  const dispatch = useDispatch()


  const testclickData = useSelector((state) => state.clickReducer)

  const clickData = testclickData?.offerlist?.docs

  const [startDate, setStartDate] = useState(null)
const mydate = new Date(startDate)
const todate = mydate.getDate()
let month = mydate.getMonth()
month = month+1
const year = mydate.getFullYear()
const finaldate = `${year}-${month<10?`0${month}`:month}-${todate<10?`0${todate}`:todate}`

 

  const [findid, setfindid] = useState('')






  const [loadingmmm,setloadingmmm] = useState(false)



  const callforclickupdate = async (number) => {
    // setCureentPage(number)
  
    setloadingmmm(true)
    await dispatch(fethclicks(number+1,15))
    setloadingmmm(false)
  }

  

  const callanydate = (days) => {
    var mysevendays = new Date();
    mysevendays.setDate(mysevendays.getDate() - days);
    return mysevendays.toISOString().split('T')[0]
  }


  // || x.storename.includes(findid) || x.clickid.toString().includes(findid) 
  const [referesloading,setreferesloading] = useState(false)

  const reloadAction =async ()=>{
    setreferesloading(true)
    await dispatch(fethclicks())
    setreferesloading(false)

  }






  return (
    <div>

<div>

<Mybtn color={'#2a2185'} title={referesloading?<Spinner size="small" type="arc" variant="brand" />:'↻'}  onClick={reloadAction} />
  </div>
      <div className='cardBox'>

        



        {
          testclickData?.allNumbers?.map((val,i)=>(
        <DashCard key={i} number={val?.value} title={val?.tilte} icon={'disc-outline'} />

          ))
        }
        

      </div>


      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        {/* <Btnwithicon color={'#2a2185'} title={'ADD DEAL/COUPON'} icon={faCirclePlus} onClick={AddofferBtnClk}/> */}
      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID" />
        </label>

        <div style={{cursor:'pointer',zIndex:8}}>
          Date
          <Calendarmy value={startDate} onChange={(e)=>setStartDate(e)}/>
        </div>


      </div>
      <Clickstable data={testclickData} paginattee={callforclickupdate} loading={loadingmmm}/>

      {/* <p>kjgjkh</p> */}

      {/* <Pagination perPage={testclickData?.limit} totalData={testclickData?.total} currentPage={testclickData?.page} paginate={paginate}/> */}

    </div>
  )
}

export default Mainclicks