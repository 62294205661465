import React from 'react'
import { Textarea } from 'react-rainbow-components';

const Textareacom = ({label,placeholder,onChange,value,name}) => {

    const containerStyles = {
        maxWidth: 950,
        margin:'25px 10px',

    };
  return (
//     <Textarea
//     id="example-textarea-1"
//     label={label}
//     rows={6}

//     placeholder={placeholder}
//     style={containerStyles}
//     onChange={onChange}
//     value={value}
//     className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
// />

<>

{/* <div> */}

    
<label htmlFor="message" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">{label}</label>
<textarea  id="message" rows="5" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholder} onChange={onChange} value={value} name={name}></textarea>

{/* </div> */}
</>

  )
}

export default Textareacom